import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function Subscriptions() {
  const [users, setUsers] = useState([]); // Store all users
  const [searchInput, setSearchInput] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("All");
  const data = ["All", "confirm", "pending","Not Verified"]; // Update status values

  const handleOptionClickP = (email) => {
    console.log("Inside pending email");
    axios
      .post(`https://nftbay-f5d4640f9efa.herokuapp.com/admin/changeSubsStatusP/${email}`)
      .then((response) => {
        console.log(response.data);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        console.error("Error fetching All Users:", error);
      });
  };

  const handleOptionClickC = (email) => {
    console.log("Inside confirm email");
    axios
      .post(`https://nftbay-f5d4640f9efa.herokuapp.com/admin/changeSubsStatusC/${email}`)
      .then((response) => {
        console.log(response.data);

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        console.error("Error fetching All Users:", error);
      });
  };

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toISOString().split("T")[0];
    return formattedDate;
  };

  const filterUsers = () => {
    console.log("All users: ");
    console.log(users);
    let filteredUsers = users;

    if (selectedStatus !== "All") {
      filteredUsers = filteredUsers.filter(
        (user) => user.subscriptionStatus === selectedStatus
      );
    }

    if (searchInput.trim() !== "") {
      filteredUsers = filteredUsers.filter((user) =>
        user.email.toLowerCase().includes(searchInput.toLowerCase())
      );
    }

    return filteredUsers;
  };


  useEffect(() =>{
    const users1 = filterUsers()

    setUsers(users1);


  }, [selectedStatus, searchInput]);

  useEffect(() => {
    axios
      .get(`https://nftbay-f5d4640f9efa.herokuapp.com/admin/getSubscriptionDetails`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching All Subscriptions:", error);
      });
  }, [handleOptionClickC,handleOptionClickP]);

  return (
    <>
      <div className="font-semibold font-inter text-2xl ml-5">
        Subscriptions
      </div>
      <div className="grid grid-cols-12 p-3 shadow-lg ml-3 mr-3 rounded-md">
        <div className="col-span-12 flex xs:flex-col md:flex-row md:justify-between md:items-center ">
          <div className="col-span-6">
            <label
              htmlFor="status"
              className="block mb-2 text-lg font-inter font-medium text-gray-900 dark:text-white"
            >
              Status
            </label>

            <Listbox value={selectedStatus} onChange={setSelectedStatus}>
              {({ open }) => (
                <div className="relative mt-2">
                  <Listbox.Button className="relative w-full min-w-[120px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                    <span className="flex items-center">
                      <span className="block truncate">{selectedStatus}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options onChange={(e)=>setSelectedStatus(e.target.value)} className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {data.map((val, index) => (
                        <Listbox.Option
                          key={val}
                          className={`${
                            selectedStatus === val
                              ? "bg-indigo-500 font-bold"
                              : "text-gray-900"
                          } px-1 py-1 w-full`}
                          value={val}
                        >
                          <div className="flex items-center justify-between w-full">
                            <span
                              className={selectedStatus === val ? "text-white" : ""}
                            >
                              {val}
                            </span>
                            {selectedStatus === val ? (
                              <span>
                                <CheckIcon
                                  className="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              )}
            </Listbox>
          </div>
          <div className="col-span-6 xs:mt-3 sm:mt-0">
            <form>
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="block w-full mt-5 px-4 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search user by email"
                  required
                />
              </div>
            </form>
          </div>
        </div>

        <div className="relative col-span-12 overflow-x-auto border mt-3 sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Code
                </th>
                <th scope="col" className="px-6 py-3">
                  Purchase Date
                </th>
                <th scope="col" className="px-6 py-3">
                  Buyer Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Payment ReferenceID
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((data, index) => (
                <tr
                  key={data._id}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    #HA-AU-003-{data.subscrptionNumber}
                  </th>
                  <td className="px-6 py-4">{formatDate(data.purchaseDate)}</td>
                  <td className="px-6 py-4">{data.email}</td>
                  <td className="px-6 py-4">{data.paymentReferenceNO}</td>
                  <td className="px-6 py-4">{data.currentPrice} Eth</td>
                  <td className="px-6 py-4">{data.subscriptionStatus}</td>
                  <td className="px-6 py-4">
                    <div className="flex">
                      <button onClick={() => handleOptionClickC(data.email)}>
                        <svg
                          baseProfile="tiny"
                          viewBox="0 0 24 24"
                          fill="green"
                          className="h-6 w-6"
                        >
                          <path d="M11 20a2.978 2.978 0 01-2.121-.879l-4-4C4.312 14.555 4 13.801 4 13s.312-1.555.879-2.122c1.133-1.133 3.109-1.133 4.242 0l1.188 1.188 3.069-5.523a2.999 2.999 0 015.507.632 2.975 2.975 0 01-.263 2.282l-5 9A3.015 3.015 0 0111 20zm-4-8c-.268 0-.518.104-.707.293S6 12.732 6 13s.104.518.293.707l4 4a1.002 1.002 0 001.581-.221l5-9a.993.993 0 00.088-.76.992.992 0 00-.478-.6 1.015 1.015 0 00-1.357.388l-4.357 7.841-3.062-3.062A.996.996 0 007 12z" />
                        </svg>
                      </button>

                      <button onClick={() => handleOptionClickP(data.email)}>
                        <svg
                          fill="none"
                          viewBox="0 0 15 15"
                          className="h-6 w-6 ml-3"
                        >
                          <path
                            fill="red"
                            fillRule="evenodd"
                            d="M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
