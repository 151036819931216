import React, { useEffect, useState, Fragment } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";

export default function Users() {
  const [users, setUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredUsers, setFilteredUsers] = useState([]); // New state for filtered users
  const [selectedRole, setSelectedRole] = useState("All");
  const data = ["All", "buyer", "seller"];

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toISOString().split("T")[0];
    return formattedDate;
  };

  const filterUsers = (role) => {
    setSelectedRole(role);
    if (role === "All") {
      if (searchInput) {
        const filteredUsersByEmail = users.filter((user) =>
          user.email.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredUsers(filteredUsersByEmail);
      } else {
        setFilteredUsers(users);
      }
    } else {
      const filteredUsersByRole = users.filter(
        (user) => user.userRole === role.toLowerCase()
      );

      if (searchInput) {
        const filteredUsersByEmail = filteredUsersByRole.filter((user) =>
          user.email.toLowerCase().includes(searchInput.toLowerCase())
        );
        setFilteredUsers(filteredUsersByEmail);
      } else {
        setFilteredUsers(filteredUsersByRole);
      }
    }
  };

  const handleDeleteUser = (email) => {
    axios
      .delete(`https://nftbay-f5d4640f9efa.herokuapp.com/admin/deleteUser/${email}`)
      .then((response) => {
        console.log(response.data);

        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        console.error("Error fetching All Users:", error);
      });
  };

  useEffect(() => {
    axios
      .get(`https://nftbay-f5d4640f9efa.herokuapp.com/admin/getAllUsers`)
      .then((response) => {
        setUsers(response.data);
        setFilteredUsers(response.data);
      })
      .catch((error) => {
        console.error("Error fetching All Users:", error);
      });
  }, [handleDeleteUser]);

  useEffect(() => {
    filterUsers(selectedRole);
  }, [selectedRole]);

  useEffect(() => {
    filterUsers(selectedRole);
  }, [searchInput]);

  return (
    <>
      <div className="font-semibold font-inter text-2xl ml-5">
        User Management
      </div>
      <div className="grid grid-cols-12 p-3 shadow-lg ml-3 mr-3 rounded-md">
        <div className="col-span-12 flex xs:flex-col md:flex-row md:justify-between md:items-center ">
          <div className="col-span-6">
            <label
              htmlFor="userRole"
              className="block text-lg font-medium font-inter text-gray-900 dark:text-white mb-2"
            >
              Role
            </label>

            <Listbox value={selectedRole} onChange={setSelectedRole}>
              {({ open }) => (
                <div className="relative mt-2">
                  <Listbox.Button className="relative w-full min-w-[120px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                    <span className="flex items-center">
                      <span className="block truncate">{selectedRole}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {data.map((val, index) => (
                        <Listbox.Option
                          key={val}
                          className={`${
                            selectedRole === val
                              ? "bg-indigo-500 font-bold"
                              : "text-gray-900"
                          } px-1 py-1 w-full`}
                          value={val}
                        >
                          <div className="flex items-center justify between w-full">
                            <span
                              className={selectedRole === val ? "text-white" : ""}
                            >
                              {val}
                            </span>
                            {selectedRole === val ? (
                              <span>
                                <CheckIcon
                                  className="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              )}
            </Listbox>
          </div>
          <div className="col-span-6 xs:mt-3 sm:mt-0">
            <form>
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                  className="block w-full mt-5 px-4 py-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search user by email"
                  required
                />
              </div>
            </form>
          </div>
        </div>

        <div className="relative col-span-12 overflow-x-auto border mt-3 sm:rounded-lg">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Code
                </th>
                <th scope="col" className="px-6 py-3">
                  Join Date
                </th>
                <th scope="col" className="px-6 py-3">
                  User Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Total sales
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((data, index) => (
                <tr
                  key={data._id}
                  className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    #HA-AU-002-{data.userNumber}
                  </th>
                  <td className="px-6 py-4">{formatDate(data.joinDate)}</td>
                  <td className="px-6 py-4">{data.email}</td>
                  <td className="px-6 py-4">{data.totalSales} Eth</td>
                  <td className="px-6 py-4">
                    <button onClick={() => handleDeleteUser(data.email)}>
                      <svg
                        viewBox="0 0 1024 1024"
                        fill="red"
                        className="h-6 w-6"
                      >
                        <path d="M360 184h-8c4.4 0 8-3.6 8-8v8h304v-8c0 4.4 3.6 8 8-8h-8v72h72v-80c0-35.3-28.7-64-64-64H352c-35.3 0-64 28.7-64 64v80h72v-72zm504 72H160c-17.7 0-32 14.3-32 32v32c0 4.4 3.6 8 8 8h60.4l24.7 523c1.6 34.1 29.8 61 63.9 61h454c34.2 0 62.3-26.8 63.9-61l24.7-523H888c4.4 0 8-3.6 8-8v-32c0-17.7-14.3-32-32-32zM731.3 840H292.7l-24.2-512h487l-24.2 512z" />
                      </svg>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
