import React from "react";
import Header from "./Components/Header";
import Footer from "./Components/Footer";

export default function ERROR404() {
  return (
    <div>
      <Header />
      <div className="flex flex-col mt-10 justify-center items-center">
        <div className="font-bold text-xl text-red-600">Page Not Found</div>
        <img className="w-56 h-56" src={require("../src/Images/error.png")} alt="Error SVG" />
      </div>
      <Footer />
    </div>
  );
}
