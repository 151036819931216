import React, { useEffect, useState } from "react";
import axios from "axios";
import Loader from "../../../Loader.json";
import Lottie from "lottie-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";

import { ReactComponent as AdminArrow } from "../../../Assets/AdminArrow.svg";
import ReactApexChart from "react-apexcharts";

export default function DashBoard() {
  const EMAIL_SERVICE_ID = "service_g9ro3f5";
  const EMAIL_TEMPLATE_ID = "template_uzvrb7k";

  const [isLoading, setIsLoading] = useState(true);
  const [monthlySubscriptions, setmonthlySubscriptions] = useState([]);
  const [monthlyRevenue, setmonthlyRevenue] = useState([]);
  const [monthlyVisits, setmonthlyVisits] = useState([]);

  const adminEmail = localStorage.getItem("adminEmail");
  const [graphDetails, setgraphDetails] = useState();
  const [recentSubsHistory, setrecentSubsHistory] = useState([]);
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toISOString().split("T")[0]; // Format: "2023-07-24"
    return formattedDate;
  };


  emailjs.init("d7zY-2uSpAfLf3UqV");

  function sendEmailToSeller(sellerEmail, nftName,paymentRefNo) {
    const templateParams = {
      sellerEmail,
      nftName,
      paymentRefNo
    };

    console.log("Email Params:", templateParams);

    emailjs
      .send(EMAIL_SERVICE_ID, EMAIL_TEMPLATE_ID, templateParams)
      .then((response) => {
        console.log("Email sent successfully:", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  }


  const [chartData] = useState({
    options: {
      chart: {
        height: 350,
        type: "bar",
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          columnWidth: 13,

          colors: {
            backgroundBarColors: ["#F0F0F0"],
            ranges: [
              {
                from: 0,
                to: 100,
                color: "#4A3AFF",
              },
            ],
          },
          strokeWidth: 1,
          barWidth: 5,
        },
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        position: "bottom",
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        min: 0,
        max: 60,
        tickAmount: 6,
        labels: {
          formatter: function (val) {
            return val;
          },
        },
      },
    },
  });

  const [revenueLineChart] = useState({
    series: [
      {
        data: [0, 41, 0, 50, 20, 0, 80],
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "line",
        toolbar: {
          show: false, // Remove the toolbar with the hamburger button
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth", // Change curve type if needed
      },
      grid: {
        show: false, // Remove the grid lines
      },

      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
        labels: {
          show: false, // Remove labels on the X axis
        },
        axisBorder: {
          show: false, // Remove the lines at the bottom of the x-axis
        },
      },
      yaxis: {
        labels: {
          show: false, // Remove labels on the Y axis
        },
      },
    },
  });

  const [vistLineChart] = useState({
    series: [
      {
        data: [0, 50, 15, 30, 49, 12, 10],
      },
    ],
    options: {
      chart: {
        height: 250,
        type: "line",
        toolbar: {
          show: false, // Remove the toolbar with the hamburger button
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth", // Change curve type if needed
      },
      grid: {
        show: false, // Remove the grid lines
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
        ],
        labels: {
          show: false, // Remove labels on the X axis
        },
        axisBorder: {
          show: false, // Remove the lines at the bottom of the x-axis
        },
      },
      yaxis: {
        labels: {
          show: false, // Remove labels on the Y axis
        },
      },
    },
  });

  const handleOptionClickP = (email) => {
    console.log("Inside pending email");
    axios
      .post(`https://nftbay-f5d4640f9efa.herokuapp.com/admin/changeSubsStatusP/${email}`)
      .then((response) => {
        console.log(response.data);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        console.error("Error fetching All Users:", error);
      });
  };

  const handleOptionClickC = (email) => {
    console.log("Inside confirm email");
    axios
      .post(`https://nftbay-f5d4640f9efa.herokuapp.com/admin/changeSubsStatusC/${email}`)
      .then((response) => {
        console.log(response.data);
      sendEmailToSeller(email);
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      })
      .catch((error) => {
        console.error("Error fetching All Users:", error);
      });
  };
  useEffect(() => {
    axios
      .get(`https://nftbay-f5d4640f9efa.herokuapp.com/admin/getReecentSubscriptions`)
      .then((response) => {
        setrecentSubsHistory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching recent subscriptions:", error);
      });
  }, [handleOptionClickC,handleOptionClickP]);

  useEffect(() => {
    axios
      .get(`https://nftbay-f5d4640f9efa.herokuapp.com/admin/monthlysubs/${adminEmail}`)
      .then((response) => {
        const monthlySubscriptionCounts =
          response.data.monthlySubscriptions.map((item) => item.count);
        // Set the result in your state variable.
        setmonthlySubscriptions(monthlySubscriptionCounts);


      })
      
      .catch((error) => {
        console.error("Error fetching monthly subscriptions:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`https://nftbay-f5d4640f9efa.herokuapp.com/admin/totalRevenue-visits/${adminEmail}`)
      .then((response) => {
        console.log(response.data);
        const monthlyRCounts = response.data.yearlyMonthlyDataRevenue.map((item) => item.revenue);
        setmonthlyRevenue(monthlyRCounts);
        const monthlyVCounts = response.data.yearlyMonthlyDataVisits.map((item) => item.visits);

        console.log("Monthly V:-");
        console.log(monthlyVCounts); // Use the updated state variable directly
        setmonthlyVisits(monthlyVCounts);
        setgraphDetails(response.data);
        setIsLoading(false); // Set loading to false after 2 seconds
      })
      .catch((error) => {
        console.error("Error fetching monthly revenue:", error);
      });
  }, [adminEmail]);
  

  return (
    <>
      {isLoading ? (
        <div className="lottie-container ">
          <Lottie animationData={Loader} />
        </div>
      ) : (
        <>
          <div className="grid grid-cols-12 p-3 gap-2 ">
            <div className="col-span-12 font-semibold p-1 text-lg font-inter border-b-2">
              Welcome back, salman
            </div>
            <div className="xs:col-span-12 xl:col-span-8 p-1 bg-[#FFF] rounded-lg shadow-md">
              <div className="font-inter font-semibold text-lg ml-5">
                Monthly Subscription
              </div>
              <div id="chart">
                <ReactApexChart
                  options={chartData.options}
                  series={[
                    {
                      name: "Subscription",
                      data: monthlySubscriptions,
                    },
                  ]}
                  type="bar"
                  height={300}
                />
              </div>
            </div>

            <div className="xs:col-span-12 xl:col-span-4 grid grid-cols-12 gap-2 justify-between">
              <div className="xs:col-span-12 lg:col-span-6 xl:col-span-12 border p-2 grid grid-cols-6 bg-[#FFF] rounded-lg shadow-md">
                <div className="col-span-6 flex flex-row justify-between h-[150px]">
                  <div>
                    <h1 className="font-semibold font-inter text-sm mt-3">
                      Total revenue
                    </h1>
                    <h2 className="font-bold text-base mt-5">
                      {graphDetails.totalRevenue} ETH
                    </h2>
                    <div className="text-green-400 text-xs font-bold flex flex-row">
                      
                      <div>
                        <AdminArrow
                          style={{
                            width: "15px",
                            height: "15px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="chart"
                    className="xs:h-[150px] sm:h-[180px] xs:w-[180px] sm:w-[190px] lg:h-[300px] xl:h-[300px] lg:w-[200px] xl:w-[200px]"
                  >
                    <ReactApexChart
                      options={revenueLineChart.options}
                      series={monthlyRevenue}
                      type="line"
                    />
                  </div>
                </div>
              </div>

              <div className="xs:col-span-12 lg:col-span-6 xl:col-span-12 border p-2 grid grid-cols-6 bg-[#FFF] rounded-lg shadow-md ">
                <div className="col-span-6 flex flex-row justify-between h-[150px]">
                  <div>
                    <h1 className="font-semibold font-inter text-sm mt-3">
                      New Vists
                    </h1>
                    <h2 className="font-bold text-2xl mt-5">
                      {graphDetails.newVisits}
                    </h2>
                    <div className="text-green-400 text-xs font-bold flex flex-row">
                    
                      <div>
                        <AdminArrow
                          style={{
                            width: "15px",
                            height: "15px",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    id="chart"
                    className="xs:h-[150px] sm:h-[180px] xs:w-[180px] sm:w-[190px] lg:h-[300px] xl:h-[300px] lg:w-[200px] xl:w-[200px]"
                  >
                    <ReactApexChart
                      options={vistLineChart.options}
                      series={monthlyVisits}
                      type="line"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-span-12  bg-[#FFF] rounded-lg shadow-md ">
              <div className="flex justify-start p-3">
                <h1 className="text-lg font-semibold font-inter">
                  Recent Subscriptions
                </h1>
              </div>

              <div class="relative col-span-12 overflow-x-auto  mt-3 sm:rounded-lg">
                <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" class="px-6 py-3">
                        Code
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Purchase Date
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Buyer Email
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Payment ReferenceID
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Status
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Amount
                      </th>
                      <th scope="col" class="px-6 py-3">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {recentSubsHistory.map((data) => (
                      <tr
                        key={data._id}
                        class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                      >
                        <th
                          scope="row"
                          class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                        >
                          #HA-AU-003-{data.subscrptionNumber}
                        </th>
                        <td class="px-6 py-4">
                          {formatDate(data.purchaseDate)}
                        </td>
                        <td class="px-6 py-4">{data.email}</td>
                        <td class="px-6 py-4">{data.paymentReferenceNO}</td>
                        <td class="px-6 py-4">{data.subscriptionStatus}</td>
                        <td class="px-6 py-4">{data.currentPrice} Eth</td>
                        <td class="px-6 py-4">
                          <div className="flex">
                            <button
                              onClick={() => handleOptionClickC(data.email)}
                            >
                              <svg
                                baseProfile="tiny"
                                viewBox="0 0 24 24"
                                fill="green"
                                className="h-6 w-6"
                              >
                                <path d="M11 20a2.978 2.978 0 01-2.121-.879l-4-4C4.312 14.555 4 13.801 4 13s.312-1.555.879-2.122c1.133-1.133 3.109-1.133 4.242 0l1.188 1.188 3.069-5.523a2.999 2.999 0 015.507.632 2.975 2.975 0 01-.263 2.282l-5 9A3.015 3.015 0 0111 20zm-4-8c-.268 0-.518.104-.707.293S6 12.732 6 13s.104.518.293.707l4 4a1.002 1.002 0 001.581-.221l5-9a.993.993 0 00.088-.76.992.992 0 00-.478-.6 1.015 1.015 0 00-1.357.388l-4.357 7.841-3.062-3.062A.996.996 0 007 12z" />
                              </svg>
                            </button>

                            <button
                              onClick={() => handleOptionClickP(data.email)}
                            >
                              <svg
                                fill="none"
                                viewBox="0 0 15 15"
                                className="h-6 w-6 ml-3"
                              >
                                <path
                                  fill="red"
                                  fillRule="evenodd"
                                  d="M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z"
                                  clipRule="evenodd"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
