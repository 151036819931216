import React, { useEffect, useState, useRef, useCallback } from "react";

import SellerHeader from "../../Components/SellerHeader";
import axios from "axios";
import { ReactComponent as Upload } from "../../Assets/Image.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TruncateText from "../../Components/TruncateText";
import { useNavigate} from "react-router-dom";

import Footer from "../../Components/Footer";

const long =
  "Lorem, ipsum dolor sit amet consectetur adipisicing elit. Maiores, ipsum.";

export default function SellerPanel() {
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [smallScreen, setSmallScreen] = useState(true);
  const [sellNFTModal, setSellNFTModal] = useState(false);
  const [name, setname] = useState("");
  const [Description, setdescription] = useState("");
  const [price, setprice] = useState();
  const [nftImage, setnftImage] = useState(null);
  const [nfts, setNFTs] = useState([]);
  const [nft, setNFT] = useState(null);
  const [totalNFTs, setTotalNFTs] = useState(0);
  const [pendingNFTs, setPendingNFTs] = useState(0);
  const [soldNFTs, setSoldNFTs] = useState(0);

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false); // Close the dropdown after selecting an option
  };

  const fileInputRef = useRef(null);
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };
  const handleFileChange = (e) => {
    setnftImage(e.target.files[0]);
  };

  const storeNFT = async (e) => {
    e.preventDefault();
    const storedemail = localStorage.getItem("userEmail");
    // Create a FormData object
    const formData = new FormData();

    // Append the email and other fields to the FormData
    formData.append("email", storedemail);
    formData.append("name", name);
    formData.append("description", Description);
    formData.append("price", price);
    formData.append("category", selectedOption);
    // Append the NFT image file to the FormData
    formData.append("nftImage", nftImage);

    try {
      const response = await axios.post(
        "https://nftbay-f5d4640f9efa.herokuapp.com/user/createNFT",
        formData, // Send the FormData object
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data);
      closeSellNFTModal();
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openSellNFTModal = () => {
    setSellNFTModal(true);
  };

  const closeSellNFTModal = () => {
    setSellNFTModal(false);
  };



  const fetchNFTDetails = useCallback(async (nftID) => {
    try {
      const response = await axios.get(
        `https://nftbay-f5d4640f9efa.herokuapp.com/user/getnftID/${nftID}`
      );

      console.log(response.data);
      setNFT(response.data.nft);
      openModal(); // Open the modal after fetching data
    } catch (error) {
      console.log(error);
    }
  }, []);



  useEffect(() => {
    // Replace with the actual API endpoint for getting NFT counts
    const storedemail = localStorage.getItem("userEmail");
    axios
      .get(`https://nftbay-f5d4640f9efa.herokuapp.com/user/getnftCount/${storedemail}`)
      .then((response) => {
        const { totalNFTs, pendingNFTs, soldNFTs } = response.data;
        setTotalNFTs(totalNFTs);
        setPendingNFTs(pendingNFTs);
        setSoldNFTs(soldNFTs);
      })
      .catch((error) => {
        console.error("Error fetching NFT counts:", error);
      });
  }, []);

  useEffect(() => {
    const storedemail = localStorage.getItem("userEmail");
    console.log(storedemail);

    axios
      .get(`https://nftbay-f5d4640f9efa.herokuapp.com/user/getAllSellerNFTs/${storedemail}`)
      .then((response) => {
        console.log(response.data);
        setNFTs(response.data);
      })
      .catch((error) => {
        console.error("Error fetching All Nfts:", error);
      });

    window.scrollTo(0, 0);
    const checkScreenSize = () => {
      if (window.innerWidth < 500) {
        setSmallScreen(true);
      } else {
        setSmallScreen(false);
      }
    };

    // Initial check
    checkScreenSize();

    // Add event listener to check screen size on resize
    window.addEventListener("resize", checkScreenSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  return (
    <>
      <SellerHeader />
      <div className="grid grid-cols-12 font-bold text-lg border border-gray-500 p-10 m-5 rounded">
        <div className="xs:col-span-12   md:col-span-4  xs:border m-1 md:border-0 flex flex-col justify-center items-center border-gray-500 md:border-r">
          <h1>{totalNFTs}</h1>
          Total NFTs
        </div>
        <div className="xs:col-span-12  md:col-span-4 flex flex-col m-1 xs:border md:border-0 justify-center items-center border-gray-500 md:border-r">
          <h1>{soldNFTs}</h1>
          Total NFTs Sold
        </div>
        <div className="xs:col-span-12  md:col-span-4 flex flex-col m-1 justify-center xs:border md:border-0 items-center border-gray-500 ">
          <h1>{pendingNFTs}</h1>
          Pending sales
        </div>
      </div>

      {sellNFTModal && (
        <div
          className="fixed top-0  left-0 right-0 z-50 w-full p-10  h-screen flex items-center justify-center bg-opacity-50 bg-gray-800"
          onClick={closeSellNFTModal}
        >
          <div
            className="relative bg-white rounded-lg md:ml-5 md:mr-5   xl:ml-60   w-full xl:mr-60 shadow dark:bg-gray-700"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-end m-5">
              <svg
                onClick={closeSellNFTModal}
                className="w-3 h-3  mt-3 cursor-pointer"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
            </div>
            <div className="grid grid-cols-12 p-3 ">
              <div className="xs:col-span-1 sm:col-span-2 md:col-span-3"></div>

              <div className="xs:col-span-10 sm:col-span-8 md:col-span-6">
                <form onSubmit={storeNFT}>
                  {!nftImage ? (
                    <div>
                      <div className="text-lg font-bold text-center">
                        Upload NFT Image
                      </div>
                      <div
                        onClick={handleFileInputClick}
                        className="flex cursor-pointer items-center justify-center mt-5"
                      >
                        <Upload />
                      </div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="flex justify-center items-center mt-5">
                        <img
                          className="h-44 w-44"
                          src={URL.createObjectURL(nftImage)}
                          alt="QR Code"
                        />
                      </div>
                    </div>
                  )}

                  <label
                    htmlFor="first_name"
                    className="block mb-2 mt-5 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Enter NFT Name
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setname(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Name"
                    required
                  />
                  <div className="grid grid-cols-12 gap-5 justify-center items-center">
                    <div className="col-span-8 ">
                      <label
                        htmlFor="first_name"
                        className="block mb-2 mt-5 text-sm font-medium text-gray-900 dark:text-white"
                      >
                        Enter NFT Price
                      </label>
                      <input
                        type="text"
                        onChange={(e) => {
                          setprice(e.target.value);
                        }}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        placeholder="Price"
                        required
                      />
                    </div>

                    <div className="col-span-4  h-full flex justify-center items-end ">
                      <div className="relative inline-block text-left ">
                        <button
                          onClick={toggleDropdown}
                          className="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          type="button"
                        >
                          {selectedOption !== "" ? selectedOption : "Category"}
                          <svg
                            className={`w-2.5 h-2.5 ml-2.5 ${
                              isOpen ? "rotate-180" : ""
                            }`}
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 10 6"
                          >
                            <path
                              stroke="currentColor"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="m1 1 4 4 4-4"
                            />
                          </svg>
                        </button>

                        {isOpen && (
                          <div
                            className="z-10 absolute right-0 mt-2 w-44 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700"
                            aria-labelledby="dropdownDefaultButton"
                          >
                            <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                              <li
                                onClick={() => handleOptionClick("Art")}
                                className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                              >
                                <button className="block px-4  py-2">
                                  Art
                                </button>
                              </li>
                              <li
                                onClick={() =>
                                  handleOptionClick("Collectibles")
                                }
                                className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                              >
                                <button className="block px-4 py-2 ">
                                  Collectibles
                                </button>
                              </li>

                              <li
                                onClick={() => handleOptionClick("Music")}
                                className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                              >
                                <button className="block px-4 py-2 ">
                                  Music
                                </button>
                              </li>

                              <li
                                onClick={() => handleOptionClick("Photography")}
                                className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                              >
                                <button className="block px-4 py-2 ">
                                  Photography
                                </button>
                              </li>

                              <li
                                onClick={() => handleOptionClick("Utility")}
                                className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                              >
                                <button className="block px-4 py-2 ">
                                  Utility
                                </button>
                              </li>

                              <li
                                onClick={() => handleOptionClick("Video")}
                                className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                              >
                                <button className="block px-4 py-2 ">
                                  Video
                                </button>
                              </li>

                              <li
                                onClick={() => handleOptionClick("Sports")}
                                className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                              >
                                <button className="block px-4 py-2">
                                  Sports
                                </button>
                              </li>

                              <li
                                onClick={() =>
                                  handleOptionClick("Virtual World")
                                }
                                className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                              >
                                <button className="block px-4 py-2 ">
                                  Virtual World
                                </button>
                              </li>
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <label
                    htmlFor="first_name"
                    className="block mb-2 mt-5 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Enter NFT Description
                  </label>
                  <input
                    type="text"
                    onChange={(e) => {
                      setdescription(e.target.value);
                    }}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Description"
                    required
                  />

                  <div className="col-span-4 flex justify-end">
                    <div className=" p-2 mt-4   border-gray-200 rounded-b dark:border-gray-600">
                      <button
                        type="submit"
                        className="text-white font-mono bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="xs:col-span-1 sm:col-span-2 md:col-span-3"></div>
            </div>
          </div>
        </div>
      )}

      <div className="ml-5 mr-5 col-span-12 flex justify-between items-center">
        <h1 className="font-bold text-lg">NFTs Listed</h1>
        <div className="flex flex-row justify-center items-center">
          <div className=" p-2   border-gray-200 rounded-b dark:border-gray-600">
            <button
              onClick={openSellNFTModal}
              className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              New NFT
            </button>
          </div>
        </div>
      </div>

      {isModalOpen && nft && (
        <div
          className="fixed top-0  left-0 right-0 z-50 w-full  flex items-center justify-center bg-opacity-50 bg-gray-800 min-h-[100vh]"
          onClick={closeModal}
        >
          <div
            className="relative bg-white rounded-lg md:ml-5 md:mr-5    shadow dark:bg-gray-700"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
              <div className="relative">
                <img
                  className="object-cover w-full rounded-t-md"
                  src={`data:image/png;base64, ${nft.nftImage}`}
                  alt={nft.name}
                />
                <div className="absolute top-0 right-0 m-5">
                  <svg
                    onClick={closeModal}
                    className="w-3 h-3 mt-3 cursor-pointer"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </div>
              </div>

              <div className="p-5">
                <div className="flex flex-row ">
                  <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    Name:
                  </h5>
                  <h1 className="flex justify-center items-center font-mono ml-2">
                    {nft.name}
                  </h1>
                </div>
                <div className="flex flex-row ">
                  <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    Price:
                  </h5>
                  <h1 className="flex justify-center items-center font-mono ml-2">
                    {nft.price} ETH
                  </h1>
                </div>
                <div className="flex flex-row ">
                  <h5 className="mb-2 text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                    Description:
                  </h5>
                  <h1 className="flex justify-center items-center font-mono ml-2">
                    {nft.description}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {nfts.length > 0 ? (
        <div className="relative overflow-x-auto shadow-md sm:rounded-lg p-5 xs:ml-5 xs:mr-5 mt-2 ">
          <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">
                  NFT Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Description
                </th>

                <th scope="col" className="px-6 py-3">
                  Price
                </th>
              </tr>
            </thead>

            <tbody>
              {nfts.map((nft) => (
                <tr
                  key={nft._id}
                  onClick={() => {
                    fetchNFTDetails(nft._id); // Pass the NFT ID to the function
                  }}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {nft.name}
                  </th>
                  <td
                    onClick={() => {
                      openModal(long);
                    }}
                    className="px-6 py-4 cursor-pointer"
                  >
                    {smallScreen ? (
                      <TruncateText maxWords={3} text={nft.description} />
                    ) : (
                      <>
                        <TruncateText maxWords={10} text={nft.description} />
                      </>
                    )}
                  </td>

                  <td className="px-6 py-4">{nft.price} ETH</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="ml-5">No NFTs Listed Yet</div>
      )}

      <div></div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />

      <Footer />
    </>
  );
}
