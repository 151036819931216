import React, { useEffect, useState } from "react";
import axios from "axios";


export default function PurchaseHistory() {
  const [PurhaseHistory, setPurhaseHistory] = useState([]);
  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toISOString().split("T")[0]; // Format: "2023-07-24"
    return formattedDate;
  };

  useEffect(() => {
    axios
      .get(`https://nftbay-f5d4640f9efa.herokuapp.com/admin/getPurchaseHistory`)
      .then((response) => {
        setPurhaseHistory(response.data);
      })
      .catch((error) => {
        console.error("Error fetching All Nfts:", error);
      });
  }, []);

  return (
    <>
      <div className="font-semibold font-inter text-2xl ml-5">Purchase History</div>
      <div className="grid grid-cols-12 p-3 shadow-lg ml-3 mr-3 rounded-md">
        <div class="relative col-span-12 overflow-x-auto border mt-3 sm:rounded-lg">
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Purchase ID
                </th>
                <th scope="col" class="px-6 py-3">
                  Purchase Date
                </th>
                <th scope="col" class="px-6 py-3">
                  Buyer Email
                </th>
                <th scope="col" class="px-6 py-3">
                  seller Email
                </th>
                <th scope="col" class="px-6 py-3">
                  Payment ReferenceID
                </th>
                <th scope="col" class="px-6 py-3">
                  Amount
                </th>
                <th scope="col" class="px-6 py-3">
                  Status
                </th>
              </tr>
            </thead>
            <tbody>
              {
                PurhaseHistory.map((data)=>(
              <tr key={data._id} class="bg-white border-b dark:bg-gray-900 dark:border-gray-700">
                <th
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  #HA-AU-004-{data.orderNumber}
                </th>
                <td class="px-6 py-4">{formatDate(data.purchaseDate)}</td>
                <td class="px-6 py-4">{data.buyerEmail}</td>
                <td class="px-6 py-4">{data.sellerEmail}</td>
                <td class="px-6 py-4">{data.referenceNumber}</td>
                <td class="px-6 py-4">{data.amount} Eth</td>
                <td class="px-6 py-4">{data.orderStatus}</td>
              </tr>

                ))
              }


             
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}
