import React from 'react';
import { useNavigate } from "react-router-dom";

export default function Button({ nftID, nftStatus }) {
  const navigate = useNavigate();

  const isButtonDisabled = nftStatus === "sold";

  return (
    <>
      <button
        onClick={() => {
          if (!isButtonDisabled) {
            navigate("/Profile/" + nftID);
          }
        }}
        className={`font-mono bg-blue-700 p-1 px-2 text-white rounded w-full ${isButtonDisabled ? 'disabled' : ''}`}
        disabled={isButtonDisabled}
      >
        BUY NOW
      </button>
    </>
  );
}
