import React, { useEffect, useState, Fragment } from "react";
import Footer from "../../Components/Footer";
import axios from "axios";
import { ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import Header from "../../Components/Header";


export default function Order() {
  const userEmail = localStorage.getItem("userEmail");
  const data = ["All", "confirm", "pending","Not Verified"]; // Update status values
  const [nfts, setNFTs] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const formattedDate = dateObject.toISOString().split("T")[0]; // Format: "2023-07-24"
    return formattedDate;
  };




  useEffect(() => {
    axios
      .get(`https://nftbay-f5d4640f9efa.herokuapp.com/user/getOrdersHistory/${userEmail}`)
      .then((response) => {
        console.log("Orders reponse:- ");

        console.log(response.data);
        setNFTs(response.data);
      })
      .catch((error) => {
        console.error("Error fetching All NFTs:", error);
      });
  }, []);

  const filteredNFTs = nfts.filter((data) => {
    return (
      (selectedStatus === "All" || data.orderStatus === selectedStatus) &&
      data.buyerEmail.includes(searchQuery)
    );
  });

  return (
    <>
      <Header/>
      <div className="font-bold text-2xl ml-5">My Orders</div>
      <div className="grid grid-cols-12 p-3 shadow-lg ml-3 mr-3 rounded-md">
        <div className="col-span-12 flex xs:flex-col sm:flex-row sm:justify-between sm:items-center ">
          <div className="col-span-6">
            <label
              htmlFor="status"
              className="block mb-2 text-lg font-medium text-gray-900 dark:text-white"
            >
              Status
            </label>
            <Listbox value={selectedStatus} onChange={setSelectedStatus}>
              {({ open }) => (
                <div className="relative mt-2">
                  <Listbox.Button className="relative w-full min-w-[120px] cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-600 sm:text-sm sm:leading-6">
                    <span className="flex items-center">
                      <span className="block truncate">{selectedStatus}</span>
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronUpDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options onChange={(e)=>setSelectedStatus(e.target.value)} className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {data.map((val, index) => (
                        <Listbox.Option
                          key={val}
                          className={`${
                            selectedStatus === val
                              ? "bg-blue-500 font-bold"
                              : "text-gray-900"
                          } px-1 py-1 w-full`}
                          value={val}
                        >
                          <div className="flex items-center justify-between w-full">
                            <span
                              className={selectedStatus === val ? "text-white" : ""}
                            >
                              {val}
                            </span>
                            {selectedStatus === val ? (
                              <span>
                                <CheckIcon
                                  className="h-5 w-5 text-white"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </div>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              )}
            </Listbox>
          </div>
          <div className="col-span-6 xs:mt-3 sm:mt-0">
            <form>
              <label
                htmlFor="default-search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search by Email
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    className="w-4 h-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 20"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                    />
                  </svg>
                </div>
                <input
                  type="search"
                  id="default-search"
                  className="block w-full mt-5 px-4 py-2  pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Search by Email"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </form>
          </div>
        </div>

        {filteredNFTs.length > 0 ? (
          <div className="relative col-span-12 overflow-x-auto border mt-3 sm:rounded-lg">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Code
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Purchase Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Seller Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Payment ReferenceID
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Amount
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {filteredNFTs.map((data) => (
                  <tr
                    key={data._id}
                    className="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      #HA-AU-01-{data.orderNumber}
                    </th>
                    <td className="px-6 py-4">{formatDate(data.purchaseDate)}</td>
                    <td className="px-6 py-4">{data.sellerEmail}</td>
                    <td className="px-6 py-4">{data.referenceNumber}</td>
                    <td className="px-6 py-4">{data.amount} Eth</td>
                    <td className="px-6 py-4">
                      {data.orderStatus}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>No Orders Yet</div>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Footer />
    </>
  );
}
