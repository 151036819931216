// AuthContext.js

import React, { createContext, useState } from 'react';

// Create the AuthContext
export const UserContext = createContext();

// Create the AuthProvider component
export const Context = ({ children }) => {
  // const [token, setToken] = useState(localStorage.getItem('token') || '');
  const [userRole, setuserRole] = useState('');



  



  return (
    <UserContext.Provider value={{ userRole, setuserRole}}>
      {children}
    </UserContext.Provider>
  );
};
