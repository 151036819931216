import React, { useState, useEffect, useCallback } from "react";
import ReactPaginate from "react-paginate";

import Header from "../../Components/Header";
import { ReactComponent as GridView } from "../../Assets/gridview.svg";
import { ReactComponent as ListView } from "../../Assets/listview.svg";
import Button from "../../Components/Button";
import Footer from "../../Components/Footer";
import Loader from "../../Loader.json";
import Lottie from "lottie-react";
import "../animation.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

import "./pagination.css";
export default function SearchByCategory() {
  const navigate = useNavigate();
  const [searchCategory, setsearchCategory] = useState("");
  const [nftDetails, setnftDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showCat, setShowCat] = useState(true);
  const [gridView, setGridView] = useState(false);
  const [listView, setListView] = useState(true);
  const [showFilter, setShowFilter] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numOfNfts, setnumOfNfts] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const perPage = 10; // Number of items per page

  const { searchInput } = useParams();

  console.log("Searched data category:- " + searchInput);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const fecthNFTDtails = useCallback(async (pageNumber) => {
    try {
      const response = await axios.get(
        `https://nftbay-f5d4640f9efa.herokuapp.com/user/getnftbyCategory/${searchInput}?page=${pageNumber}`
      );
      console.log(response.data);
      setnumOfNfts(response.data.numberOfNFTs);
      console.log("Search category: " + searchCategory);
      setnftDetails(response.data.nftsWithUsers);
      const totalPages = Math.ceil(response.data.numberOfNFTs / perPage);
      setTotalPages(totalPages);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(localStorage.getItem("userEmail"));
    fecthNFTDtails(currentPage + 1);

    window.scrollTo(0, 0);
    const checkScreenSize = () => {
      if (window.innerWidth < 1024) {
        setShowFilter(true);
      } else {
        setShowFilter(false);
      }
    };

    // Initial check
    checkScreenSize();

    // Add event listener to check screen size on resize
    window.addEventListener("resize", checkScreenSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, [window.location.pathname]);

  return (
    <>
      <Header />
      <div className="grid grid-cols-12 p-3 gap-2">
        <div className="xs:hidden lg:block lg:col-span-2 lg:mt-2">
          <div>
            <div className="border-t pt-1 border-gray-300 flex justify-between font-semibold">
              Categories
              {showCat ? (
                <img
                  className="cursor-pointer"
                  src={require("../../Images/expand_more.png")}
                  alt="Arrow"
                  onClick={() => {
                    setShowCat(false);
                  }}
                />
              ) : (
                <img
                  className="cursor-pointer"
                  alt="Arrow"
                  src={require("../../Images/expand_less.png")}
                  onClick={() => {
                    setShowCat(true);
                  }}
                />
              )}
            </div>
          </div>

          {showCat && (
            <>
              <div className="flex items-center mb-4 mt-3">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  value=""
                  checked={searchInput === "Art"}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Art
                </label>
              </div>

              <div className="flex items-center mb-4 mt-3">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  checked={searchInput === "Collectibles"}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Collectibles
                </label>
              </div>
              <div className="flex items-center mb-4 mt-3">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  checked={searchInput === "Music"}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Music
                </label>
              </div>
              <div className="flex items-center mb-4 mt-3">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  checked={searchInput === "Photography"}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Photography
                </label>
              </div>
              <div className="flex items-center mb-4 mt-3">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  checked={searchInput === "Video"}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Video
                </label>
              </div>
              <div className="flex items-center mb-4 mt-3">
                <input
                  id="default-checkbox"
                  checked={searchInput === "Utility"}
                  type="checkbox"
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Utility
                </label>
              </div>
              <div className="flex items-center mb-4 mt-3">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  checked={searchInput === "Sports"}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Sports
                </label>
              </div>
              <div className="flex items-center mb-4 mt-3">
                <input
                  id="default-checkbox"
                  type="checkbox"
                  checked={searchInput === 'Virtual World'}
                  className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                  htmlFor="default-checkbox"
                  className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                  Virtual World
                </label>
              </div>
            </>
          )}
        </div>

        {isLoading ? (
          <div className="lottie-container">
            <Lottie animationData={Loader} />
          </div>
        ) : (
          <div className="xs:col-span-12 lg:col-span-10 p-2 ">
            <div className="grid grid-cols-12 border border-slate-300 p-2 mb-1 rounded">
              <div className="xs:col-span-5 md:col-span-4 mt-1">
                {numOfNfts && numOfNfts + " NTFs Found"}
              </div>
              <div className="xs:col-span-1 md:col-span-4"></div>
              <div className="xs:col-span-6 md:col-span-4 flex justify-end">
                <div className="flex">
                  {/* <select
                    id="countries"
                    defaultValue="None"
                    className="bg-gray-50 xs:hidden sm:block border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  >
                    <option>Featured</option>
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                    <option value="FR">France</option>
                    <option value="DE">Germany</option>
                  </select> */}
                  <div
                    className={`border border-gray-300 ${
                      gridView ? "bg-slate-200" : ""
                    } flex ml-2 p-1 rounded-s-lg `}
                  >
                    <button
                      onClick={() => {
                        setListView(false);
                        setGridView(true);
                      }}
                    >
                      <GridView
                        style={{
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    </button>
                  </div>
                  <div
                    className={`border border-gray-300 ${
                      listView ? "bg-slate-200" : ""
                    } flex  p-1 rounded-r-lg `}
                  >
                    <button
                      onClick={() => {
                        setGridView(false);
                        setListView(true);
                      }}
                    >
                      <ListView
                        style={{
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    </button>
                  </div>

                  {showFilter && (
                    <div>
                      <svg
                        fill="none"
                        onClick={openModal}
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        viewBox="0 0 24 24"
                        className="mt-1 h-7 w-7 ml-1 cursor-pointer"
                      >
                        <path d="M22 3H2l8 9.46V19l4 2v-8.54L22 3z" />
                      </svg>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {isModalOpen && (
              <div
                className="fixed top-0  left-0 right-0 z-50 w-full p-10  h-screen flex items-center justify-center bg-opacity-50 bg-gray-800"
                onClick={closeModal}
              >
                <div
                  className="relative bg-white rounded-lg shadow dark:bg-gray-700"
                  onClick={(e) => e.stopPropagation()}
                >
                  {/* Modal header */}
                  <div className="flex items-start  justify-between p-5 border-b rounded-t dark:border-gray-600">
                    <h3 className="text-xl font-semibold mr-5 text-gray-900 dark:text-white">
                      Selected Category
                    </h3>
                    <button
                      onClick={closeModal}
                      type="button"
                      className="text-gray-400 ml-10  bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                    >
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only ">Close modal</span>
                    </button>
                  </div>

                  <div className="p-5 space-y-6">
                    <div className="flex items-center mb-4 mt-3">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        value=""
                        checked={searchInput === "Art"}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Art
                      </label>
                    </div>

                    <div className="flex items-center mb-4 mt-3">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        checked={searchInput === "Collectibles"}
                        value=""
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Collectibles
                      </label>
                    </div>
                    <div className="flex items-center mb-4 mt-3">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        checked={searchInput === "Music"}
                        value=""
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Music
                      </label>
                    </div>
                    <div className="flex items-center mb-4 mt-3">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        checked={searchInput === "Photography"}
                        value=""
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Photography
                      </label>
                    </div>
                    <div className="flex items-center mb-4 mt-3">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        checked={searchInput === "Video"}
                        value=""
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Video
                      </label>
                    </div>
                    <div className="flex items-center mb-4 mt-3">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        checked={searchInput === "Utility"}
                        value=""
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Utility
                      </label>
                    </div>
                    <div className="flex items-center mb-4 mt-3">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        checked={searchInput === "Sports"}
                        value=""
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Sports
                      </label>
                    </div>
                    <div className="flex items-center mb-4 mt-3">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        checked={searchInput === "Virtual World"}
                        value=""
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      <label
                        htmlFor="default-checkbox"
                        className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Virtual World
                      </label>
                    </div>
                  </div>

                  <div className="flex  justify-end p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      onClick={closeModal}
                      type="button"
                      className="text-gray-500  bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                    >
                      Close
                    </button>
                    {/* <button
                      onClick={closeModal}
                      type="button"
                      className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Apply
                    </button> */}
                  </div>
                </div>
              </div>
            )}

            <div className="grid grid-cols-12">
              {nftDetails.length ? (
                listView ? (
                  <>
                    {nftDetails.map((data) => (
                      <div
                        key={data.nft._id}
                        className="border border-gray-300 p-3 rounded mt-2 col-span-12  gap-2 "
                      >
                        <div className="grid grid-cols-12 gap-2">
                          <div className="xs:col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2">
                            <img
                              className="w-full h-full rounded-xl  object-cover"
                              alt="Arrow"
                              src={`data:image/png;base64, ${data.nft.nftImage}`}
                            />
                          </div>
                          <div className="xs:col-span-6  sm:col-span-8 md:col-span-9 flex flex-col justify-between lg:col-span-10">
                            <h1 className="col-span-10 font-inter text-lg font-semibold">
                              {data.nft.name}
                            </h1>
                            <div className="col-span-2 font-mono">
                              {data.nft.price} ETH
                            </div>
                            <div className="xs:hidden sm:block col-span-10 mt-5">
                              {data.nft.description}
                            </div>
                            <div className="col-span-10  flex mt-1 justify-between items-center">
                              <div
                                onClick={() => {
                                  navigate("/Profile/" + data.nft._id);
                                }}
                                className=" xs:text-xs cursor-pointer underline sm:text-base sm:col-span-5 xs:col-span-10 text-blue-700 font-semibold"
                              >
                                View details
                              </div>

                              <div className="xs:col-span-10 sm:col-span-5 ">
                                <Button nftID={data.nft._id} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <>
                    <div className="border border-gray-300 p-3 rounded mt-2 col-span-12  ">
                      <div className="grid grid-cols-12 p-3 gap-5 ">
                        {nftDetails.map((data) => (
                          <div
                            key={data._id}
                            className="xs:col-span-12 sm:col-span-6  md:col-span-6  lg:col-span-4 xl:col-span-3"
                          >
                            <img
                              src={`data:image/png;base64, ${data.nft.nftImage}`}
                              alt="DistantGalaxy"
                              className="w-full rounded-xl sm:h-[370px] md:h-[310px]  lg:h-[300px] xl:h-[300px]"
                            />
                            <div className="grid grid-cols-12">
                              <div className="xs:col-span-12 md:col-span-8 lg:col-span-12  ">
                                <div className="xs:text-sm font-inter md:text-lg  mt-3 text-black font-semibold">
                                  {data.nft.name}
                                </div>
                                <div className=" flex mt-3">
                                  {!data.user.pimage ? (
                                    <img
                                      src={require("../../Images/user.png")}
                                      alt="DistantGalaxy1"
                                      className="w-5 h-5 rounded-full"
                                    />
                                  ) : (
                                    <img
                                      src={`data:image/png;base64, ${data.user.pimage}`}
                                      alt={data.user.username}
                                      className="h-5 w-5 rounded-full "
                                    />
                                  )}

                                  <div className="font-mono text-[#3B3B3B] ml-3 xs:text-[12px] md:text-sm ">
                                    {data.user.username}
                                  </div>
                                </div>
                                <div className="font-mono text-[#858584] mt-3">
                                  Price
                                </div>
                                <div className="xs:col-span-0 md:col-span-4  lg:col-span-5 xl:col-span-6"></div>
                              </div>
                            </div>
                            <div className="flex justify-between items-center">
                              <div className=" font-mono text-[#3B3B3B] mt-1 ">
                                {data.nft.price} ETH
                              </div>
                              <div className=" xs:mt-5 md:mt-0  sm:flex sm:justify-end">
                                <Button nftID={data.nft._id} />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </>
                )
              ) : (
                <p className="col-span-12">No NFTs Yet</p>
              )}
            </div>
            <div className="flex justify-end col-span-10 mt-2 gap-2">
              {/* Render pagination component with styling */}
              <ReactPaginate
                pageCount={totalPages}
                pageRangeDisplayed={5}
                marginPagesDisplayed={2}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                forcePage={currentPage}
                containerClassName={"pagination"}
                previousLabel={"Previous"}
                nextLabel={"Next"}
                activeClassName={"active"}
                pageClassName="pagination-item" // Style for individual page numbers
                breakClassName="pagination-break" // Style for the "..." ellipsis
                previousClassName="pagination-previous" // Style for the "Previous" button
                nextClassName="pagination-next" // Style for the "Next" button
                disabledClassName="pagination-disabled" // Style for disabled elements
              />
            </div>
          </div>
        )}
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Footer />
    </>
  );
}
