import React, { useState, useEffect, useCallback } from "react";
import Loader from "../../Loader.json";
import Lottie from "lottie-react";
import "../animation.css";
import copy from "clipboard-copy";
import { useParams } from "react-router-dom";
import Header from "../../Components/Header";
import Button from "../../Components/Button";
import Footer from "../../Components/Footer";
import axios from "axios";
import emailjs from "emailjs-com";

import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Profile2() {
  const EMAIL_SERVICE_ID = "service_g9ro3f5";
  const EMAIL_TEMPLATE_ID = "template_eau0bne";

  const [nftDetails, setnftDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const [textToCopy, setTextToCopy] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [nftName, setnftName] = useState("");
  const [description, setDiscription] = useState(true);
  const [aboutSeller, setAboutSeller] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [progress, setProgress] = useState(33);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [ModalStep, setModalStep] = useState(1);
  const [sellerData, setsellerData] = useState();

  const [mylikeNfts, setmylikeNfts] = useState([]);
  const [cataegory, setCataegory] = useState();

  const [paymentRefNo, setPaymentRefNo] = useState("");
  const [buyerName, setBuyerName] = useState("");
  const [buyerEmail, setbuyerEmail] = useState("");
  let { nftID } = useParams();

  emailjs.init("d7zY-2uSpAfLf3UqV");

  function sendEmailToSeller(sellerEmail,paymentRefNo,buyerEmail,nftName) {
    console.log("Refeernec number: " + paymentRefNo);
    const templateParams = {
      SE: `${sellerEmail}`,
      RN: `${paymentRefNo}`,
      BE: `${buyerEmail}`,
      NM : `${nftName}`
    };

    console.log("NFT name is " + nftName);

    console.log("Email Params:", templateParams);

    emailjs
      .send(EMAIL_SERVICE_ID, EMAIL_TEMPLATE_ID, templateParams)
      .then((response) => {
        console.log("Email sent successfully:", response);
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  }

  const buyNFT = async (e) => {
    e.preventDefault();
    const storedemail = nftDetails.user.email;

    console.log("Seller email:- " + storedemail);

    console.log("Data From use states...");

    console.log(storedemail);
    console.log(paymentRefNo);
    console.log(buyerEmail);
    console.log(buyerName);
    console.log(nftDetails.nft.price);
    try {
      // Create a FormData object

      const response = await axios.post(
        "https://nftbay-f5d4640f9efa.herokuapp.com/user/buyNFT",
        {
          buyerName,
          nftID,
          amount: nftDetails.nft.price,
          buyerEmail,
          referenceNumber: paymentRefNo,
          sellerEmail: storedemail,
        },
        {
          headers: {
            "Content-Type": "application/json", // Set the content type to JSON
          },
        }
      );

      console.log(response.data.message);
      sendEmailToSeller(storedemail,paymentRefNo,buyerEmail,nftName);
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      closeModal();
    } catch (error) {
      console.error("Error:", error);
      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      
    }
  };

  const fecthSellerDetails = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://nftbay-f5d4640f9efa.herokuapp.com/user/getSellerQrcode/${nftID}`
      );
      console.log("SELASD DEATILS:::");
      console.log(response.data);
      setsellerData(response.data);
      setTextToCopy(response.data.walletAddress);
    } catch (error) {
      console.log(error);
    }
  }, [nftID]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextStep = () => {
    setModalStep((ModalStep) => ModalStep + 1);
    setProgress((progress) => progress + 33);
  };

  const handleCopyToClipboard = async () => {
    try {
      await copy(textToCopy);
      setIsCopied(true);
    } catch (error) {
      console.error("Error copying to clipboard: ", error);
    }
  };

  const backStep = () => {
    if (progress === 33) {
      setProgress(33);
    }
    setModalStep((ModalStep) => ModalStep - 1);
    setProgress((progress) => progress - 33);
  };

  const fecthNFTDtails = useCallback(async () => {
    console.log(nftID);

    try {
      const response = await axios.get(
        `https://nftbay-f5d4640f9efa.herokuapp.com/user/getnftDetails/${nftID}`
      );

      console.log("NFT DETAILS DAta");
      console.log(response.data.nft.name);
      setnftDetails(response.data);
      setnftName(response.data.nft.name);

      setCataegory(response.data.nft.category);

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  }, [nftID]);

  useEffect(() => {
    axios
      .get(`https://nftbay-f5d4640f9efa.herokuapp.com/user/getPendingNFTsMaylike/${cataegory}`)
      .then((response) => {
        console.log("May like NFts");
        console.log(response.data.nftsWithUsers);
        setmylikeNfts(response.data.nftsWithUsers);
        setIsLoading(false);
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, [cataegory]);

  useEffect(() => {
    fecthSellerDetails();
  }, [fecthSellerDetails]);

  useEffect(() => {
    fecthNFTDtails();

    window.scrollTo(0, 0);
    const checkScreenSize = () => {
      if (window.innerWidth < 768) {
        setShowProgressBar(true);
      } else {
        setShowProgressBar(false);
      }
    };

    // Initial check
    checkScreenSize();

    // Add event listener to check screen size on resize
    window.addEventListener("resize", checkScreenSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, [fecthNFTDtails]);

  return (
    <div>
      <Header />
      {isLoading ? (
        <div className="lottie-container">
          <Lottie animationData={Loader} />
        </div>
      ) : (
        <div className="p-3">
          <div className="grid grid-cols-12  border border-gray-300 rounded">
            <div className="xs:col-span-12 lg:col-span-9  p-3 flex ">
              <img
                className="w-full xs:h-[400px] md:h-[400px]   lg:h-[400px] xl:h-[500px] "
                alt="Background"
                src={`data:image/png;base64, ${nftDetails.nft.nftImage}`}
              />
            </div>
            <div className="xs:col-span-12 xs:p-3 lg:col-span-3 mt-2 ">
              <div className="font-mono col-span-3 text-sm text-[#858584]">
                Price
              </div>
              <div className="font-mono col-span-3">
                {nftDetails.nft.price} ETH
              </div>
              <div className="border border-gray-300  grid grid-cols-12 p-3 rounded gap-2 mt-10">
                <div className="col-span-12 flex gap-2">
                  {!nftDetails.nft.pimage ? (
                    <img
                      src={require("../../Images/user.png")}
                      alt={nftDetails.user.fname}
                      className="h-14 w-14 rounded-lg"
                    />
                  ) : (
                    <img
                      src={`data:image/png;base64, ${nftDetails.nft.pimage}`}
                      alt={nftDetails.nft.fname}
                      className="h-24 w-24 rounded-lg"
                    />
                  )}

                  <div className="">
                    <div className=" font-bold text-lg text-[#858584] font-mono">
                      Seller
                    </div>
                    <div className=" font-semibold ">
                      {nftDetails.user.username}
                    </div>
                  </div>
                </div>
                <div className="col-span-12 border-t border-gray-300"></div>
                <div className="col-span-12 gap-4 flex">
                  <img
                    className=" h-max"
                    alt="User"
                    src={require("../../Images/verified_user.png")}
                  />
                  <div className=" text-sm">Verified Seller</div>
                </div>
                <div onClick={openModal} className="col-span-12  rounded">
                  <Button nftID={nftID} nftStatus={nftDetails.nft.status} />
                </div>
                <div className="col-span-12">
                  <button
                    onClick={() => {
                      navigate("/User/" + nftDetails.user.email);
                    }}
                    type="button"
                    className="p-1 font-medium w-full font-mono  text-blue-900 focus:outline-none bg-white rounded border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10"
                  >
                    Seller's Profile
                  </button>
                </div>
              </div>
            </div>
          </div>

          {isModalOpen && (
            <div
              className="fixed top-0  left-0 right-0 z-50 w-full p-10  h-screen flex items-center justify-center bg-opacity-50 bg-gray-800"
              onClick={closeModal}
            >
              <div
                className="relative bg-white rounded-lg md:ml-5 md:mr-5   xl:ml-60   w-full xl:mr-60 shadow dark:bg-gray-700"
                onClick={(e) => e.stopPropagation()}
              >
                <div className="flex justify-end m-5">
                  <svg
                    onClick={closeModal}
                    className="w-3 h-3  mt-3 cursor-pointer"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </div>

                {showProgressBar ? (
                  <div className="grid grid-cols-12  p-5 ">
                    <div className="w-full col-span-12 bg-gray-200 rounded-full dark:bg-gray-700">
                      <div
                        className="bg-blue-600 text-xs font-medium text-blue-100 text-center p-0.5 leading-none rounded-full"
                        style={{ width: `${progress}%` }}
                      >
                        {" "}
                        {`${progress}%`}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="grid grid-cols-12  pl-5 pr-5 pb-5  ">
                    <div className="md:col-span-1 xl:col-span-2"></div>
                    <div className="md:col-span-10  xl:col-span-8 mt-5  ">
                      <ol className="items-center w-full space-y-4 sm:flex sm:space-x-4 sm:space-y-0">
                        <li className=" flex items-center text-blue-600 dark:text-blue-500 space-x-2.5">
                          <span className="flex items-center justify-center w-8 h-8 border border-blue-600 dark:border-blue-500 rounded-full shrink-0 ">
                            1
                          </span>
                          <span>
                            <h3 className="font-mono leading-tight text-sm">
                              Enter Details
                            </h3>
                            <p className="text-xs">
                              Add your email address and name
                            </p>
                          </span>
                        </li>
                        <li
                          className={`flex items-center  ${
                            ModalStep === 1
                              ? "text-gray-500 dark:text-gray-400"
                              : "text-blue-600 dark:text-blue-500"
                          }   space-x-2.5`}
                        >
                          <span
                            className={`flex items-center justify-center w-8 h-8 border ${
                              ModalStep === 1
                                ? "border-gray-500 dark:border-gray-400"
                                : " border-blue-600 dark:border-blue-500"
                            }  rounded-full shrink-0 `}
                          >
                            2
                          </span>
                          <span>
                            <h3 className="font-mono leading-tight text-sm">
                              Transfer Funds
                            </h3>
                            <p className="text-xs">
                              Transfer funds to Seller Account using QR code
                            </p>
                          </span>
                        </li>
                        <li
                          className={`flex items-center  ${
                            ModalStep === 3
                              ? "text-blue-600 dark:text-blue-500"
                              : "text-gray-500 dark:text-gray-400"
                          }   space-x-2.5`}
                        >
                          <span
                            className={`flex items-center justify-center w-8 h-8 border ${
                              ModalStep === 3
                                ? " border-blue-600 dark:border-blue-500"
                                : "border-gray-500 dark:border-gray-400"
                            }  rounded-full shrink-0 `}
                          >
                            3
                          </span>
                          <span>
                            <h3 className="font-mono leading-tight text-sm">
                              Add Reference Number
                            </h3>
                            <p className="text-xs">
                              Please add reference number of the payment
                            </p>
                          </span>
                        </li>
                      </ol>
                    </div>
                    <div className="md:col-span-1 lg:col-span-1 xl:col-span-2"></div>
                  </div>
                )}

                <div className="grid grid-cols-12  pl-5 pr-5 pb-5  ">
                  {ModalStep === 1 ? (
                    <div className="col-span-12 mt-5 grid grid-cols-12">
                      <div className="xs:col-span-1 md:col-span-3 lg:col-span-4"></div>
                      <div className="xs:col-span-10 md:col-span-6 lg:col-span-4 h-72 ">
                        <label
                          htmlFor="first_name"
                          className="block mb-2 w-full text-sm mt-8 font-medium text-gray-900 dark:text-white"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          onChange={(e) => {
                            setbuyerEmail(e.target.value);
                          }}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Enter Email address"
                          required
                        />

                        <label
                          htmlFor="first_name"
                          className="block mb-2 w-full text-sm font-medium text-gray-900 dark:text-white mt-5"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          onChange={(e) => {
                            setBuyerName(e.target.value);
                          }}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="First Name"
                          required
                        />

                        <div className="flex  justify-end mt-5   border-gray-200 rounded-b dark:border-gray-600">
                          <button
                            onClick={nextStep}
                            type="button"
                            className="text-white font-mono bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            Next
                          </button>
                        </div>

                        <div className="col-span-3"></div>
                      </div>
                      <div className="xs:col-span-1 md:col-span-3 lg:col-span-4"></div>
                    </div>
                  ) : ModalStep === 2 ? (
                    <>
                      <div className="col-span-12 mt-5 grid grid-cols-12  ">
                        <div className="col-span-4"></div>
                        <div className="col-span-4  md:h-72 ">
                          <div className="text-blue-500 text-center">
                            Scan QR Code
                          </div>
                          <div className="xs:hidden md:block text-xs text-center">
                            Please enter your email while transferring the funds
                            in notes
                          </div>
                          <div className="flex items-center justify-center mt-2">
                            <img
                              className="w-28"
                              src={`data:image/png;base64, ${sellerData.qrcode}`}
                              alt="QR Code"
                            />
                          </div>

                          <div className="flex items-center justify-center mt-3">
                            <button
                              type="button"
                              onClick={handleCopyToClipboard}
                              className="text-white font-mono flex justify-between bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                              <svg
                                viewBox="0 0 1024 1024"
                                className="h-5 w-5"
                                fill="currentColor"
                              >
                                <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z" />
                              </svg>
                              {isCopied ? "Copied!" : textToCopy}
                            </button>
                          </div>
                          <div className="flex xs:flex-col md:flex-row  mt-5 justify-between  border-gray-200 rounded-b dark:border-gray-600">
                            <button
                              onClick={backStep}
                              className="text-black border border-blue-700 font-mono  hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-slate-100 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-slate-50 dark:hover:bg-slate-300 dark:focus:ring-slate-800"
                            >
                              Back
                            </button>

                            <button
                              onClick={nextStep}
                              type="button"
                              className="text-white xs:mt-3 md:mt-0 font-mono bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                              Next
                            </button>
                          </div>
                          <div className="col-span-4"></div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-span-12 mt-10 grid grid-cols-12">
                      <div className="col-span-4"></div>
                      <div className="col-span-5  h-72 ">
                        <label
                          htmlFor="first_name"
                          className="block mb-2 mt-10 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Reference Number
                        </label>
                        <input
                          type="text"
                          onChange={(e) => {
                            setPaymentRefNo(e.target.value);
                          }}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Enter Reference number of payment"
                          required
                        />
                        <div className="flex xs:flex-col md:flex-row  mt-5 justify-between  border-gray-200 rounded-b dark:border-gray-600">
                          <button
                            onClick={backStep}
                            className="text-black border border-blue-700 font-mono  hover:bg-blue-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-slate-100 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-slate-50 dark:hover:bg-slate-300 dark:focus:ring-slate-800"
                          >
                            Back
                          </button>

                          <button
                            onClick={buyNFT}
                            className="text-white xs:mt-3 md:mt-0 font-mono bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                          >
                            Finish
                          </button>
                        </div>
                      </div>

                      <div className="col-span-3"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          <div className="grid grid-cols-12 mt-5 gap-5">
            <div className="xs:col-span-12 lg:col-span-9 border border-gray-300  rounded">
              <div className="flex p-2  ">
                <div
                  className={`cursor-pointer p-2 ${
                    description
                      ? "text-blue-600 font-bold border-b-2 border-blue-600"
                      : "text-gray-400"
                  }`}
                  onClick={() => {
                    setDiscription(true);
                    setAboutSeller(false);
                  }}
                >
                  Discription
                </div>
                <div
                  className={`ml-5 cursor-pointer p-2 ${
                    aboutSeller
                      ? "text-blue-700 font-semibold border-b-2 border-blue-600 "
                      : "text-gray-400"
                  }`}
                  onClick={() => {
                    setAboutSeller(true);
                    setDiscription(false);
                  }}
                >
                  About Seller
                </div>
              </div>
              <div className="col-span-9 p-3">
                {description ? (
                  <p>{nftDetails.nft.description}</p>
                ) : (
                  <>
                    <div className="grid grid-cols-12 p-3">
                      {!nftDetails.nft.pimage ? (
                        <img
                          src={require("../../Images/user.png")}
                          alt={nftDetails.user.fname}
                          className="h-14 w-14 rounded-lg"
                        />
                      ) : (
                        <img
                          src={`data:image/png;base64, ${nftDetails.nft.pimage}`}
                          alt={nftDetails.nft.fname}
                          className="h-24 w-24 rounded-lg"
                        />
                      )}
                      <h1 className="xs:col-span-5 ml-3  md:col-span-4 md:mt-3 xs:text-base sm:text-lg font-bold">
                        {nftDetails.user.username}
                      </h1>
                    </div>

                    <div className="grid grid-cols-12">
                      <div className="xs:col-span-6 sm:col-span-4 mt-2  text-[#858584] font-mono">
                        <div>
                          Total NTFs Listed
                          <div className="text-[#3B3B3B]">
                            {nftDetails.user.pendingnft}
                          </div>
                        </div>
                      </div>
                      <div className="xs:col-span-6 sm:col-span-4 mt-2  text-[#858584] font-mono">
                        Total NTFs Sold
                        <div className="text-[#3B3B3B]">
                          {nftDetails.user.soldnft}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className="xs:col-span-12 lg:col-span-3 border border-gray-300 rounded p-3">
              <h1 className="font-semibold ">You may like</h1>
              {mylikeNfts.length > 0 ? (
                <div className="grid grid-cols-12  lg:gap-2 mt-2">
                  {mylikeNfts.map((data) => (
                    <>
                      <div className="xs:col-span-4 sm:col-span-3 md:col-span-2 lg:col-span-5">
                        <div className=" lg:border border-gray-300 p-2 rounded">
                          <img
                            onClick={() => {
                              navigate("/Profile/" + data.nft._id);
                            }}
                            className="h-max cursor-pointer"
                            alt="Galaxy"
                            src={`data:image/png;base64, ${data.nft.nftImage}`}
                          />
                        </div>
                      </div>
                      <div className="xs:col-span-6 py-2 sm:col-span-3 md:col-span-2 lg:col-span-7 flex flex-col justify-between ">
                        <h1 className="text-[#1C1C1C] font-semibold ">
                          {data.nft.name}
                        </h1>

                        <div className="">{data.nft.price} ETH</div>
                      </div>
                    </>
                  ))}
                </div>
              ) : (
                <div>No NFTs Yet</div>
              )}
            </div>
          </div>
        </div>
      )}
      <Footer />

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </div>
  );
}
