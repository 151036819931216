import "./index.css";
import { Route, Routes, Navigate } from "react-router-dom";
import React, {  useEffect, useLayoutEffect,  } from "react";
import { useNavigate } from "react-router-dom";

import LandingPage from "./Screens/LandingPage/LandingPage";
import Search from "./Screens/Search/Search";
import Dummy from "./Screens/Search/dummy";

import Profile from "./Screens/Profile/Profile";
import SignUp from "./Screens/SignUp/SignUp";
import Login from "./Screens/Login/Login";
import User from "./Screens/User/User";
import Seller from "./Screens/Seller/Seller";
import SellerPanel from "./Screens/SellerPanel/SellerPanel";
import Subscription from "./Screens/Subscription/Subscription";
import Payment from "./Screens/Payment/Payment";
import Contact from "./Screens/ContactUs/Contact";
import Order from "./Screens/Orders/Order";
import SellerProfile from "./Screens/SellerProfile/SellerProfile";
import MyOrder from "./Screens/MyOrders/MyOrders";
import AdminLogin from "./Screens/Admin/login/AdminLogin";
import APanel from "./Screens/Admin/login/APanel";
import DashBoard from "./Screens/Admin/login/DashBoard";
import Users from "./Screens/Admin/login/Users";
import Subscriptions from "./Screens/Admin/login/Subscriptions";
import PurchaseHistory from "./Screens/Admin/login/PurchaseHistory";
import Settings from "./Screens/Admin/login/Settings";
import QRCodeUpload from "./QRCodeUpload";
import SearchByCategory from "./Screens/Search/SearchByCategory";
import SearchAllNfts from "./Screens/Search/SearchAllNfts";
import BuyerProfile from "./Screens/BuyerProfile/BuyerProfile";
import ERROR404 from "./ERROR404";


function App() {
  const navigate = useNavigate();


  useEffect(() => {
    const allowedSellerPaths = ["/SPanel", "/Subscription", "/SellerProfile", "/Order"];
    const currentPath = window.location.pathname;
    
    if (allowedSellerPaths.includes(currentPath) && localStorage.getItem("role") !== "seller") {
      navigate("/LandingPage");
    }
  }, [window.location.pathname]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate replace to="/LandingPage" />} />
        <Route path="/LandingPage" element={<LandingPage />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/SPanel" element={<SellerPanel />} />
        <Route path="/SellerProfile" element={<SellerProfile />} />
        <Route path="/Subscription" element={<Subscription />} />
        <Route path="/Order" element={<Order />} />

        <Route path="/Search/:searchInput/:nftName" element={<Search />} />
        <Route
          path="/SearchByCategory/:searchInput"
          element={<SearchByCategory />}
        />
        <Route path="/SearchAllNfts" element={<SearchAllNfts />} />
        <Route path="/Profile/:nftID" element={<Profile />} />
        <Route path="/BProfile" element={<BuyerProfile />} />
        <Route path="/User/:email" element={<User />} />
        <Route path="/Seller" element={<Seller />} />
        <Route path="/dummy" element={<Dummy />} />
        <Route path="/Payment" element={<Payment />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/QRCode" element={<QRCodeUpload />} />
        <Route path="/Order" element={<Order />} />
        <Route path="/MyOrders" element={<MyOrder />} />
        <Route path="/Admin" element={<AdminLogin />} />
        <Route path="*" element={<ERROR404 />} />
        <Route path="/APanel/" element={<APanel />}>
          <Route path="DashBoard" element={<DashBoard />} />
          <Route path="Users" element={<Users />} />
          <Route path="Subscriptions" element={<Subscriptions />} />
          <Route path="History" element={<PurchaseHistory />} />
          <Route path="Settings" element={<Settings />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
