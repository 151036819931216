import React, { useEffect, useState } from "react";
import copy from "clipboard-copy";

import Header from "../../Components/SellerHeader";
import Footer from "../../Components/Footer";
import { useForm } from "react-hook-form";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Subscription() {
  const [adminQrcodeImage, setaminQRCodeImage] = useState(null);
  const [textToCopy, setTextToCopy] = useState();
  const [isCopied, setIsCopied] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log("inside on submit function" + data);
    data.email = localStorage.getItem("userEmail");
    console.log(data.adminNote);
    console.log(data.paymentReferenceNO);
    console.log(data.email);
    try {
      const response = await axios.post(
        "https://nftbay-f5d4640f9efa.herokuapp.com/user/renewSubscription",
        data
      );

      console.log(response);
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      reset();
    } catch (error) {
      console.error("Error:", error);
      console.log(error.response.data.error);

      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleCopyToClipboard = async () => {
    try {
      await copy(textToCopy);
      setIsCopied(true);
    } catch (error) {
      console.error("Error copying to clipboard: ", error);
    }
  };

  useEffect(() => {
    axios
      .get("https://nftbay-f5d4640f9efa.herokuapp.com/admin/get-qrcode/salman001@gmail.com")
      .then((response) => {
        const { qrcode, myReferenceID } = response.data; // Parse the JSON response

        setaminQRCodeImage(qrcode);
        setTextToCopy(myReferenceID);
      })
      .catch((error) => {
        console.error("Error fetching QR code:", error);
      });
  }, []);

  return (
    <>
      <Header />
      <div className="flex flex-col jus items-center mt-5">
        <div className="font-bold text-2xl text-center">Subscription</div>

        <div className="flex items-center justify-center mt-2">
          <img
            className="w-36"
            src={`data:image/png;base64, ${adminQrcodeImage}`}
            alt="QR Code"
          />
        </div>

        <div className="flex items-center justify-center mt-3">
          <button
            type="button"
            onClick={handleCopyToClipboard}
            className="text-white font-mono flex justify-between bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            <svg
              viewBox="0 0 1024 1024"
              className="h-5 w-5"
              fill="currentColor"
            >
              <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z" />
            </svg>
            {isCopied ? "Copied!" : textToCopy}
          </button>
        </div>

        <form onSubmit={handleSubmit(onSubmit)}>
          <div className=" w-64">
            <label
              htmlFor="first_name"
              className="block mb-2 mt-10 text-sm font-medium text-gray-900 dark:text-white"
            >
              Reference Number
            </label>
            <input
              {...register("paymentReferenceNO", {
                required: "Reference Number is required",
              })}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Reference number of payment"
              required
            />

            {errors.RN && (
              <p
                id="filled_error_help"
                className=" text-xs text-red-600 dark:text-red-400"
              >
                <span className="font-medium">{errors.RN.message}</span>
              </p>
            )}

            <label
              htmlFor="first_name"
              className="block mb-2 mt-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Add note for Admin (Optional)
            </label>
            <input
              {...register("adminNote", {})}
              placeholder="Note"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            {errors.Note && (
              <p
                id="filled_error_help"
                className=" text-xs text-red-600 dark:text-red-400"
              >
                <span className="font-medium">{errors.Note.message}</span>
              </p>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="text-white   bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 mt-3 "
            >
              Confirm
            </button>
          </div>
        </form>
      </div>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Footer />
    </>
  );
}
