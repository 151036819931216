import React, { useState, useCallback, useEffect } from "react";

import Header from "../../Components/Header";
import Footer from "../../Components/Footer";
import Loader from "../../Loader.json";
import Lottie from "lottie-react";
import "../animation.css";
import { useParams } from "react-router-dom";

import axios from "axios";

export default function User() {
  let { email } = useParams();

  const [profileDetails, setprofileDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const fecthProfileDtails = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://nftbay-f5d4640f9efa.herokuapp.com/user/userProfileDtails/${email}`
      );
      setIsLoading(false); 
      console.log(response.data);
      setprofileDetails(response.data);
    } catch (error) {
      console.log(error);
    }
  }, [email]);

  useEffect(() => {
    fecthProfileDtails();
   
  }, [fecthProfileDtails]);

  return (
    <>
      <Header />

      {isLoading ? (
        <div className="lottie-container">
          <Lottie animationData={Loader} />
        </div>
      ) : (
        <>
          <div className="relative mt-5">
            {/* Cover Picture */}
            <div className="w-full h-64 bg-cover bg-center relative">
              <img
                src={require("../../Images/SignUpBackgroundImage.png")}
                alt="Cover"
                className="w-full h-full  object-cover"
              />
              {/* Overlay */}
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50"></div>
            </div>

            {/* Profile Picture */}
            <div className="absolute  left-20 transform -translate-x-1/2 -translate-y-14">
              {!profileDetails?.pimage ? (
                <img
                  src={require("../../Images/user.png")}
                  alt={profileDetails.fname}
                  className="h-24 w-24 rounded-lg"
                />
              ) : (
                <img
                  src={`data:image/png;base64, ${profileDetails.pimage}`}
                  alt={profileDetails.fname}
                  className="h-24 w-24 rounded-lg"
                />
              )}
            </div>
          </div>
          <div className="grid grid-cols-12 ml-5 mt-12 ">
            <div className="col-span-12 text-4xl font-semibold ">
              {profileDetails.username}
            </div>
          </div>

          <div className="flex">
            <div className="col-span-6 p-5 font-mono ">
              <div className="font-semibold text-2xl">
                {profileDetails.listed}
              </div>
              <div>Owned NFTs</div>
            </div>

            <div className="ml-5 col-span-6 p-5 font-mono ">
              <div className="font-semibold text-2xl">
                {profileDetails.sold}
              </div>
              <div>Sold NFTs</div>
            </div>
          </div>
        </>
      )}
      <Footer />
    </>
  );
}
