import React, { useRef, useState, useCallback, useEffect } from "react";

import Loader from "../../Loader.json";
import Lottie from "lottie-react";
import "../animation.css";


import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SellerHeader from "../../Components/SellerHeader";
import Footer from "../../Components/Footer";
import { useForm } from "react-hook-form";
import axios from "axios";

export default function SellerProfile() {

  const [pimage, setPImage] = useState(null);
  const [profileDetails, setprofileDetails] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const storedemail = localStorage.getItem("userEmail");

  const fileInputRef = useRef(null);

  // Function to open the file input dialog
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };

  // Function to handle file selection
  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setPImage(selectedFile);
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    console.log("Inside Profile details-")
       const data1 = {
      fname : data.fname,
      lname : data.lname,
      nemail : data.nemail,
      password : data.password,
      npassword : data.npassword,
      cnpassword : data.cnpassword,
      email : storedemail,
      pimage : pimage

    }

    console.log("Data object:", data1);

    const formData = new FormData();
    formData.append("fname", data1.fname);
    formData.append("lname", data1.lname);
    formData.append("nemail", data1.nemail);
    formData.append("password", data1.password);
    formData.append("npassword", data1.npassword);
    formData.append("cnpassword", data1.cnpassword);
    formData.append("email", data1.email); 
    formData.append("pimage", pimage);

    console.log("FormData:", formData);


    try {
      const response = await axios.post(
        "https://nftbay-f5d4640f9efa.herokuapp.com/user/editProfileDetails1",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response);
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      reset();
    } catch (error) {
      console.error("Error:", error);
      console.log(error.response.data.error);

      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const onSubmit1 = async (data) => {
    console.log("inside on submit 1 function:");
    console.log(data.fname);
    console.log(data.lname);
    console.log(data.nemail);
    console.log(data.password);
    console.log(data.npassword);
    console.log(data.cnpassword);
    console.log(storedemail);

    // Create a FormData object to send the data along with the image
    const formData = new FormData();
    formData.append("fname", data.fname);
    formData.append("lname", data.lname);
    formData.append("nemail", data.nemail);
    formData.append("password", data.password);
    formData.append("npassword", data.npassword);
    formData.append("cnpassword", data.cnpassword);
    formData.append("email", storedemail); // Set your email here

    try {
      const response = await axios.post(
        "https://nftbay-f5d4640f9efa.herokuapp.com/user/editProfileDetails",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response);
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      reset();
    } catch (error) {
      console.error("Error:", error);
      console.log(error.response.data.error);

      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const fecthProfileDtails = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://nftbay-f5d4640f9efa.herokuapp.com/user/userProfileDtails/${storedemail}`
      );
      setIsLoading(false); 
      console.log(response.data);
      setprofileDetails(response.data);
    } catch (error) {
      setIsLoading(false); 
      console.log(error);
    }
  }, [storedemail]);

  useEffect(() => {
   
    fecthProfileDtails();
   
  }, [fecthProfileDtails]);

  return (
    <>
      <SellerHeader />

      {isLoading ? (
        <div className="lottie-container">
          <Lottie animationData={Loader} />
        </div>
      ) : (
        <div>
          <div className="grid grid-cols-12 p-3">
            <div className="xs:col-span-1 md:col-span-2 lg:col-span-3"></div>
            <div className="xs:col-span-10 md:col-span-8 lg:col-span-6 xs:flex-col sm:flex sm:flex-row  justify-between items-center">
              <div className="flex ">
                <div className="h-20 w-20 rounded-full ">
                {!profileDetails.pimage ? (
                        <img
                          src={require("../../Images/user.png")}
                          alt={profileDetails.fname}
                          className="h-20 w-20 rounded-lg"
                        />
                      ) : (
                        <img
                          src={`data:image/png;base64, ${profileDetails.pimage}`}
                          alt={profileDetails.fname}
                          className="h-24 w-24 rounded-lg "
                        />
                      )}
                </div>
                <div className="ml-3 mt-1">
                  <div className="text-sm mt-1">
                    <p>Upload image for your Profile,</p>
                    <p>Image size min.4mb (JPG, JPEG, PNG, SVG)</p>
                  </div>
                </div>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <div
                onClick={handleFileInputClick}
                className="xs:w-14 cursor-pointer xs:mt-2 sm:mt-0 xs:ml-2 sm:ml-0 p-2 border border-blue-500 rounded-2xl text-xs font-semibold"
              >
                Upload
              </div>
            </div>
            <div className="xs:col-span-1 md:col-span-2 lg:col-span-3"></div>
            <div className="col-span-3"></div>
            <div className="font-semibold flex justify-center text-lg col-span-6 ml-1 mt-1">
              Details
            </div>
            <div className="col-span-3"></div>
          </div>
          <div className="xs:mx-[70px]  md:mx-[150px] lg:mx-[320px] xl:mx-[400px]">
            <form
              onSubmit={
                pimage ? handleSubmit(onSubmit) : handleSubmit(onSubmit1)
              }
            >
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label
                    htmlFor="first_name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    First name
                  </label>
                  <input
                    type="text"
                    {...register("fname")}
                    defaultValue={profileDetails.fname}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {errors.fname && (
                    <p
                      id="filled_error_help"
                      className=" text-xs text-red-600 dark:text-red-400"
                    >
                      <span className="font-medium">
                        {errors.fname.message}
                      </span>
                    </p>
                  )}
                </div>
                <div>
                  <label
                    htmlFor="last_name"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Last name
                  </label>
                  <input
                    type="text"
                    {...register("lname")}
                    defaultValue={profileDetails.lname}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {errors.lname && (
                    <p
                      id="filled_error_help"
                      className=" text-xs text-red-600 dark:text-red-400"
                    >
                      <span className="font-medium">
                        {errors.lname.message}
                      </span>
                    </p>
                  )}
                </div>
              </div>

              <div className="mb-6">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Email address
                </label>
                <input
                  type="email"
                  {...register("nemail", {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  })}
                  defaultValue={profileDetails.email}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
                {errors.email && (
                  <p
                    id="filled_error_help"
                    className=" text-xs text-red-600 dark:text-red-400"
                  >
                    <span className="font-medium">{errors.email.message}</span>
                  </p>
                )}
              </div>

              <div className="font-semibold text-xl ">Change Password</div>

              <div className="mb-6 mt-3">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Current Password
                </label>
                <input
                  type="password"
                  {...register("password")}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="•••••••••"
                />
                {errors.password && (
                  <p
                    id="filled_error_help"
                    className=" text-xs text-red-600 dark:text-red-400"
                  >
                    <span className="font-medium">
                      {errors.password.message}
                    </span>
                  </p>
                )}
              </div>

              <div className="mb-6">
                <label
                  htmlFor="confirm_password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  New password
                </label>
                <input
                  type="password"
                  {...register("npassword")}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="•••••••••"
                />
                {errors.npassword && (
                  <p
                    id="filled_error_help"
                    className=" text-xs text-red-600 dark:text-red-400"
                  >
                    <span className="font-medium">
                      {errors.npassword.message}
                    </span>
                  </p>
                )}
              </div>

              <div className="mb-6">
                <label
                  htmlFor="confirm_password"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Confirm New password
                </label>
                <input
                  type="password"
                  {...register("cnpassword")}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="•••••••••"
                />
                {errors.cnpassword && (
                  <p
                    id="filled_error_help"
                    className=" text-xs text-red-600 dark:text-red-400"
                  >
                    <span className="font-medium">
                      {errors.cnpassword.message}
                    </span>
                  </p>
                )}
              </div>
              <div className="flex justify-between">
                <div></div>

                <button
                  type="submit"
                  className="text-white  bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <Footer />
    </>
  );
}
