import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BsShopWindow } from "react-icons/bs";
import Footer from "../../Components/Footer";

import { Context } from "../../Context";

import { ReactComponent as Upload } from "../../Assets/Image.svg";
import copy from "clipboard-copy";

export default function Seller() {
  // const {setuserRole} = useContext(Context);
  const navigate = useNavigate();
  const userRole = localStorage.getItem("role");
  console.log("User role: " + userRole);
  const [qrcodeFile, setQRCodeFile] = useState(null);
  const [nftImage, setnftImage] = useState(null);
  const [adminQrcodeImage, setaminQRCodeImage] = useState(null);
  const [textToCopy, setTextToCopy] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [isUserLogin, setUserLogin] = useState(false);
  const [walletAddress, setwalletAddress] = useState("");
  const [progress, setProgress] = useState(20);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [sellerStep, setsellerStep] = useState(1);
  const [paymentRefNo, setPaymentRefNo] = useState("");
  const [name, setname] = useState("");
  const [description, setdescription] = useState("");
  const [price, setprice] = useState();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false); // Close the dropdown after selecting an option
  };

  const fileInputRef = useRef(null);

  // Function to open the file input dialog
  const handleFileInputClick = () => {
    fileInputRef.current.click();
  };
  const handleQRCodeFileChange = (e) => {
    const file = e.target.files[0];
    setQRCodeFile(file);
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    console.log("inside on submit 1 function" + data);
    data.email = localStorage.getItem("userEmail");
    try {
      const response = await axios.post(
        "https://nftbay-f5d4640f9efa.herokuapp.com/user/becomeseller",
        data
      );
      reset();
      console.log(response.data);

      localStorage.setItem("username", response.data.username);

      nextStep();
    } catch (error) {
      console.error("Error:", error);
      console.log(error.response.data.error);

      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const onSubmit1 = async (data) => {
    console.log("inside on submit 1 function" + data);
    try {
      const response = await axios.post(
        "https://nftbay-f5d4640f9efa.herokuapp.com/user/becomeseller1",
        data
      );
      reset();
      console.log(response.data);
      localStorage.setItem("userEmail", response.data.email);
      localStorage.setItem("username", response.data.username);
      nextStep();
    } catch (error) {
      console.error("Error:", error);
      console.log(error.response.data.error);

      toast.error(error.response.data.error, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const onSubmit2 = async (e) => {
    e.preventDefault();
    const storedemail = localStorage.getItem("userEmail");
    console.log(storedemail);
    console.log("---");
    console.log(localStorage.getItem("userEmail"));
    try {
      const formData = new FormData();
      formData.append("email", storedemail);
      formData.append("walletAddress", walletAddress);
      formData.append("qrcode", qrcodeFile); // Make sure qrcodeFile is a File object

      const response = await axios.post(
        "https://nftbay-f5d4640f9efa.herokuapp.com/user/becomeseller2",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data);
      nextStep();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const onSubmit3 = async (e) => {
    e.preventDefault();
    const storedemail = localStorage.getItem("userEmail");

    try {
      const data = {
        email: storedemail,
        paymentReferenceNO: paymentRefNo,
      };

      const response = await axios.post(
        "https://nftbay-f5d4640f9efa.herokuapp.com/user/becomeseller3",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response.data);
      localStorage.setItem("role", "seller");
      // setuserRole("seller");

      nextStep();
    } catch (error) {
      
      console.error("Error123:", error);
    }
  };

  const storeNFT = async (e) => {
    e.preventDefault();
    const storedemail = localStorage.getItem("userEmail");
    console.log(storedemail);
    // Create a FormData object
    const formData = new FormData();

    // Append the email and other fields to the FormData
    formData.append("email", storedemail);
    formData.append("name", name);
    formData.append("description", description);
    formData.append("price", price);
    formData.append("category", selectedOption);

    // Append the NFT image file to the FormData
    formData.append("nftImage", nftImage);

    try {
      const response = await axios.post(
        "https://nftbay-f5d4640f9efa.herokuapp.com/user/createNFT",
        formData, // Send the FormData object
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      console.log(response.data);
      nextStep();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Function to handle file selection
  const handleFileChange = (e) => {
    setnftImage(e.target.files[0]);
  };

  const nextStep = (step) => {
    if (step) {
      setsellerStep(() => step);
      setProgress((progress) => progress + 20);

      if (sellerStep === 5) {
        navigate("/SPanel");
      }
    } else {
      setsellerStep((sellerStep) => sellerStep + 1);
      setProgress((progress) => progress + 20);

      if (sellerStep === 4) {
        navigate("/SPanel");
      }
    }
  };

  useEffect(() => {
    const Token = localStorage.getItem("accessToken");
    if (Token) {
      setUserLogin(true);
    }

    axios
      .get("https://nftbay-f5d4640f9efa.herokuapp.com/admin/get-qrcode/salman001@gmail.com")
      .then((response) => {
        const { qrcode, myReferenceID } = response.data; // Parse the JSON response

        setaminQRCodeImage(qrcode);
        setTextToCopy(myReferenceID);
      })
      .catch((error) => {
        console.error("Error fetching QR code:", error);
      });

    window.scrollTo(0, 0);
    const checkScreenSize = () => {
      if (window.innerWidth < 1024) {
        setShowProgressBar(true);
      } else {
        setShowProgressBar(false);
      }
    };

    // Initial check
    checkScreenSize();

    // Add event listener to check screen size on resize
    window.addEventListener("resize", checkScreenSize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", checkScreenSize);
    };
  }, []);

  const handleCopyToClipboard = async () => {
    try {
      await copy(textToCopy);
      setIsCopied(true);
    } catch (error) {
      console.error("Error copying to clipboard: ", error);
    }
  };

  const StarperData = [
    {
      Heading: "Set up Account",
      Description: "Provide account setup details to begin selling.",
    },
    {
      Heading: "Provide Payment details",
      Description: "Wallet details to receive funds.",
    },
    {
      Heading: "Buy Subscription",
      Description: " Transfer funds to provided wallet to continue.",
    },
    {
      Heading: "List your first NFT",
      Description: "Add your first NFT to the marketplace.",
    },
  ];
  return (
    <>
      <div className="grid grid-cols-12 p-5">
        <div className="col-span-3">
          <div className="flex mt-2">
            <BsShopWindow
              style={{ width: "25px", height: "25px", color: "blue" }}
            />
            <h2 className="ml-[10px] xs:text-base text-xl  text-blue-600">
              NTFBAY
            </h2>
          </div>
        </div>
        <div className="col-span-12 text-center font-bold text-2xl mt-5">
          Become A Seller
        </div>

        {showProgressBar ? (
          <div className=" col-span-9 p-5 ">
            <div className="w-full xs:ml-10 sm:ml-20 md:ml-32     bg-gray-200 rounded-full dark:bg-gray-700">
              <div
                className="bg-blue-600  text-xs font-medium text-blue-100 text-center p-1 leading-none rounded-full"
                style={{ width: `${progress}%` }}
              >
                {" "}
                {`${progress}%`}
              </div>
            </div>
          </div>
        ) : (
          <div className="ml-16 mt-5 col-span-12 ">
            <ol className="items-center grid-cols-12  w-full space-y-4 sm:flex sm:space-x-4 sm:space-y-0">
              {StarperData.map((data, index) => (
                <li
                  className={`flex items-center w-full  col-span-3 ${
                    sellerStep <= index
                      ? "text-gray-500 dark:text-gray-400"
                      : "text-blue-500 dark:text-blue-400 "
                  }   space-x-2.5`}
                >
                  <span
                    className={`flex items-center justify-center w-8 h-8 border ${
                      sellerStep <= index
                        ? " border-gray-500 dark:border-gray-400"
                        : "border-blue-500 dark:border-blue-400"
                    } rounded-full shrink-0 `}
                  >
                    {index + 1}
                  </span>
                  <span>
                    <h3 className="font-medium leading-tight">
                      {data.Heading}
                    </h3>
                    <p className="text-sm">{data.Description}</p>
                  </span>
                </li>
              ))}
            </ol>
          </div>
        )}

        <div className="col-span-12 grid grid-cols-12 mt-2">
          <div className="xs:col-span-2 md:col-span-4"></div>

          {sellerStep === 1 ? (
            <div className="xs:col-span-8 md:col-span-4">
              <form
                onSubmit={
                  isUserLogin ? handleSubmit(onSubmit) : handleSubmit(onSubmit1)
                }
              >
                <label
                  htmlFor="first_name"
                  className="block mb-2 mt-5 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Enter Your Username
                </label>
                <input
                  {...register("username", {
                    required: "Name is required",
                  })}
                  type="text"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Username"
                  required
                />

                {errors.username && (
                  <p
                    id="filled_error_help"
                    className=" text-xs text-red-600 dark:text-red-400"
                  >
                    <span className="font-medium">
                      {errors.username.message}
                    </span>
                  </p>
                )}

                {isUserLogin === true ? (
                  <div className="col-span-4 flex justify-end">
                    <div className=" p-2 mt-4   border-gray-200 rounded-b dark:border-gray-600">
                      <button
                        type="submit"
                        className="text-white font-mono bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}

                {isUserLogin === true ? (
                  <div></div>
                ) : (
                  <div>
                    <label
                      htmlFor="first_name"
                      className="block mb-2 mt-5 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Enter Your Password
                    </label>
                    <input
                      {...register("password", {
                        required: "Password is required",
                      })}
                      type="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Password"
                      required
                    />
                    {errors.password && (
                      <p
                        id="filled_error_help"
                        className=" text-xs text-red-600 dark:text-red-400"
                      >
                        <span className="font-medium">
                          {errors.password.message}
                        </span>
                      </p>
                    )}
                    <label
                      htmlFor="first_name"
                      className="block mb-2 mt-5 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Enter Your Email
                    </label>
                    <input
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "invalid email address",
                        },
                      })}
                      type="email"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder=" Email "
                      required
                    />

                    {errors.email && (
                      <p
                        id="filled_error_help"
                        className=" text-xs text-red-600 dark:text-red-400"
                      >
                        <span className="font-medium">
                          {errors.email.message}
                        </span>
                      </p>
                    )}

                    <div className="col-span-4 flex justify-end">
                      <div className=" p-2 mt-4   border-gray-200 rounded-b dark:border-gray-600">
                        <button
                          type="submit"
                          className="text-white font-mono bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          ) : sellerStep === 2 ? (
            <div className="xs:col-span-8 md:col-span-4">
              <form onSubmit={onSubmit2}>
                <label
                  htmlFor="first_name"
                  className="block mb-2 mt-5 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Enter Your Wallet address
                </label>
                <input
                  type="text"
                  value={walletAddress}
                  onChange={(e) => {
                    setwalletAddress(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Wallet address"
                  required
                />

                {!qrcodeFile ? (
                  <div className="text-xs text-center mt-5">
                    Upload QR code that holds your wallet address
                  </div>
                ) : (
                  <div></div>
                )}

                {!qrcodeFile ? (
                  <div
                    onClick={handleFileInputClick}
                    className="flex cursor-pointer items-center justify-center mt-5"
                  >
                    <Upload />
                  </div>
                ) : (
                  <div className="flex justify-center items-center mt-5">
                    <img
                      className="h-44 w-44"
                      src={URL.createObjectURL(qrcodeFile)}
                      alt="QR Code"
                    />
                  </div>
                )}

                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  onChange={handleQRCodeFileChange}
                />
                <div className="col-span-4 flex justify-between items-center">
                  <div className=" p-2 mt-4   border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      onClick={() => {
                        setsellerStep(sellerStep - 1);
                        setProgress((progress) => progress - 20);
                      }}
                      className="text-black font-mono  mr-2 bg-white border border-blue-700 cursor-pointer hover:bg-blue-600 hover:text-white  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Back
                    </button>
                  </div>

                  <div className=" p-2 mt-4   border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      type="submit"
                      className="text-white font-mono bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : sellerStep === 3 ? (
            <div className="xs:col-span-8 md:col-span-4 mt-5">
              <form onSubmit={onSubmit3}>
                <div className="text-blue-500 text-center">Scan QR Code</div>
                <div className="text-xs text-center">
                  Please enter your email while transferring the funds in notes
                </div>
                <div className="flex items-center justify-center mt-2">
                  <img
                    className="w-24 h-24"
                    src={`data:image/png;base64, ${adminQrcodeImage}`}
                    alt="QR Code"
                  />
                </div>

                <div className="flex items-center justify-center mt-3">
                  <button
                    type="button"
                    onClick={handleCopyToClipboard}
                    className="text-white font-mono flex justify-between bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-6 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    <svg
                      viewBox="0 0 1024 1024"
                      className="h-5 w-5"
                      fill="currentColor"
                    >
                      <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z" />
                    </svg>
                    {isCopied ? "Copied!" : textToCopy}
                  </button>
                </div>

                <label
                  htmlFor="first_name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Reference Number
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setPaymentRefNo(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Enter Reference number of payment"
                  required
                />
                <div className="col-span-4 flex justify-between items-center">
                  <div className=" p-2 mt-4   border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      onClick={() => {
                        setsellerStep(sellerStep - 1);
                        setProgress((progress) => progress - 20);
                      }}
                      className="text-black font-mono  mr-2 bg-white border border-blue-700 cursor-pointer hover:bg-blue-600 hover:text-white  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Back
                    </button>
                  </div>

                  <div className=" p-2 mt-4   border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      type="submit"
                      className="text-white font-mono bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Next
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="xs:col-span-8 md:col-span-4">
              <form onSubmit={storeNFT}>
                {!nftImage ? (
                  <div>
                    <div className="text-lg font-bold text-center">
                      Upload NFT Image
                    </div>
                    <div
                      onClick={handleFileInputClick}
                      className="flex cursor-pointer items-center justify-center mt-5"
                    >
                      <Upload />
                    </div>
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileChange}
                    />
                  </div>
                ) : (
                  <div className="flex justify-center items-center mt-5">
                    <img
                      className="h-44 w-44"
                      src={URL.createObjectURL(nftImage)}
                      alt="QR Code"
                    />
                  </div>
                )}

                <label
                  htmlFor="first_name"
                  className="block mb-2 mt-5 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Enter NFT Name
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setname(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Name"
                  required
                />
                <div className="grid grid-cols-12 gap-5 justify-center items-center">
                  <div className="col-span-8">
                    <label
                      htmlFor="first_name"
                      className="block mb-2 mt-5 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Enter NFT Price
                    </label>
                    <input
                      type="text"
                      onChange={(e) => {
                        setprice(e.target.value);
                      }}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Price"
                      required
                    />
                  </div>

                  <div className="col-span-4  flex h-full justify-center   items-end">
                    <div className="relative inline-block text-left ">
                      <button
                        onClick={toggleDropdown}
                        className="text-white w-full bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        type="button"
                      >
                        {selectedOption !== "" ? selectedOption : "Category"}
                        <svg
                          className={`w-2.5 h-2.5 ml-2.5 ${
                            isOpen ? "rotate-180" : ""
                          }`}
                          aria-hidden="true"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 10 6"
                        >
                          <path
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="m1 1 4 4 4-4"
                          />
                        </svg>
                      </button>

                      {isOpen && (
                        <div
                          className="z-10 absolute right-0 mt-2 w-44 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700"
                          aria-labelledby="dropdownDefaultButton"
                        >
                          <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                            <li
                              onClick={() => handleOptionClick("Art")}
                              className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                            >
                              <button className="block px-4  py-2">Art</button>
                            </li>
                            <li
                              onClick={() => handleOptionClick("Collectibles")}
                              className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                            >
                              <button className="block px-4 py-2 ">
                                Collectibles
                              </button>
                            </li>

                            <li
                              onClick={() => handleOptionClick("Music")}
                              className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                            >
                              <button className="block px-4 py-2 ">
                                Music
                              </button>
                            </li>

                            <li
                              onClick={() => handleOptionClick("Photography")}
                              className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                            >
                              <button className="block px-4 py-2 ">
                                Photography
                              </button>
                            </li>

                            <li
                              onClick={() => handleOptionClick("Utility")}
                              className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                            >
                              <button className="block px-4 py-2 ">
                                Utility
                              </button>
                            </li>

                            <li
                              onClick={() => handleOptionClick("Video")}
                              className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                            >
                              <button className="block px-4 py-2 ">
                                Video
                              </button>
                            </li>

                            <li
                              onClick={() => handleOptionClick("Sports")}
                              className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                            >
                              <button className="block px-4 py-2">
                                Sports
                              </button>
                            </li>

                            <li
                              onClick={() => handleOptionClick("Virtual World")}
                              className=" hover:bg-gray-200 dark:hover:bg-gray-700 dark:hover:text-white cursor-pointer"
                            >
                              <button className="block px-4 py-2 ">
                                Virtual World
                              </button>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <label
                  htmlFor="first_name"
                  className="block mb-2 mt-5 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Enter NFT Description
                </label>
                <input
                  type="text"
                  onChange={(e) => {
                    setdescription(e.target.value);
                  }}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Description"
                  value={description}
                  required
                />

                <div className="col-span-4 flex justify-between items-center">
                  <div className=" p-2 mt-4   border-gray-200 rounded-b dark:border-gray-600">
                    <button
                      onClick={() => {
                        setsellerStep(sellerStep - 1);
                        setProgress((progress) => progress - 20);
                      }}
                      type="submit"
                      className="text-black font-mono  mr-2 bg-white border border-blue-700 cursor-pointer hover:bg-blue-600 hover:text-white  focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                    >
                      Back
                    </button>
                  </div>

                  <div className="flex flex-row">
                    <div className="p-2 mt-4  border-gray-200 rounded-b dark:border-gray-600">
                      <button
                        onClick={() => {
                          navigate("/SPanel");
                        }}
                        type="button"
                        className="text-white font-mono bg-slate-300 hover:bg-slate-300 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-slate-600 dark:hover:bg-slate-700 dark:focus:ring-slate-800"
                      >
                        Skip
                      </button>
                    </div>

                    <div className=" p-2 mt-4   border-gray-200 rounded-b dark:border-gray-600">
                      <button
                        type="submit"
                        className="text-white font-mono bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          )}

          <div className="xs:col-span-3 md:col-span-4 "></div>
        </div>
      </div>

      <Footer />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </>
  );
}
